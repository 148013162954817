import AboutUs from "../../components/aboutus/AboutUs";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import "./AboutUsView.css";

function AboutUsView() {
  return (
    <>
      <Header />
        <AboutUs />
      <Footer />
    </>
  );
}

export default AboutUsView;
