import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import "./BlogCard.css";
import { getDateFromTimeMillis } from "../../utils/TimeUtils";

const handleRead = (blogId) => {
  return (window.location.href = `/blogs/${blogId}`);
};

const BlogCard = (props) => {
  const {
    title,
    description,
    author,
    thumbnailUrl,
    creationTimeMillis,
    blogId,
  } = props;
  return (
    <>
      {/* <Card style={{ width: '22rem', cursor:"pointer",padding: "0px" }} onClick={() => handleRead(blogId)}>
                <Card.Img variant="top" src={thumbnailUrl} style={{height: '12rem', paddingTop: '0px'}}/>
                <div style={{position: "absolute", top: "0", width: "100%"}}>
                    <div style={{display: "flex", justifyContent: "space-between", paddingLeft: "1rem", paddingRight: '1rem', paddingBottom: '0px'}}>
                        <div>
                            {author}
                        </div>
                        <div>
                            {getDateFromTimeMillis(creationTimeMillis)}
                        </div>
                    </div>
                </div>
                <Card.Body>
                    <Card.Title>{title}</Card.Title>
                    <Card.Text className={"text-ellipsis-2-lines"}>
                        {description}
                    </Card.Text>
                    <Button variant="secondary">{"Read >>"}</Button>
                </Card.Body>
            </Card> */}
      <article
        className="post post-grid"
        onClick={() => handleRead(blogId)}
        style={{ borderTop: "1px solid lightgray" }}
      >
        <div className="entry-thumbnail">
          <a href="#" title="Top 6 Membership Sites for Beginners">
            <img
              src={thumbnailUrl}
              alt="Top 6 Membership Sites for Beginners"
            />
          </a>
        </div>
        <div className="entry-content">
          {/* <div className="entry-category">
                        <a href="#" title="Marketing">{author}</a>
                        <a href="#" title="Creative" style={{float: 'right'}}>{getDateFromTimeMillis(creationTimeMillis)}</a>
                    </div> */}
          <div className="entry-title">
            <h5 className={"text-ellipsis-2-lines"}>
              <a href="#" title="Top 6 Membership Sites for Beginners">
                {title}
              </a>
            </h5>
            <Card.Text className={"text-ellipsis-2-lines"}>
              {description}
            </Card.Text>
            <Button variant="secondary">{"Read More"} &#x2192;</Button>
          </div>
        </div>
      </article>
    </>
  );
};

export default BlogCard;
