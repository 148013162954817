import { useParams } from "react-router-dom";
import Header from "../../../components/header/Header";
import { useEffect, useState } from "react";
import RestClient from "../../../services/RestClient";
import CreateEditJob from "../../../components/admin/job/CreateEditJob";

function EditJobView() {
  const { jobId } = useParams();
  const [job, setJob] = useState({});

  useEffect(() => {
    RestClient.doGet(`jobs/${jobId}`)
      .then((response) => {
        console.log(response);
        setJob(response.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [jobId]);
  return (
    <>
      <Header />
      <CreateEditJob isEdit={true} jobId={jobId} jobData={job} />
    </>
  );
}

export default EditJobView;
