import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import BlogSection from "../components/homepage/BlogSection";
import Curriculum from "../components/homepage/Curriculum";
import EnggStats from "../components/homepage/EnggStats";
import Features from "../components/homepage/Features";
import HomeBanner from "../components/homepage/HomeBanner";
import RoadMap from "../components/homepage/RoadMap";
import FreeProgram from "../components/homepage/FreeProgramCTA";
import Programs from "../components/programs/Programs";
import HomeBannerNew from "../components/homepage/HomeBannerNew";

const HomePageView = () => {
  return (
    <div id="wrapper">
      <Header />
      <main id="main" class="site-main">
        <div class="site-content pt0">
          <HomeBannerNew />
          <EnggStats />
          <RoadMap />
          <Features />
          {/* <FreeProgram /> */}
          <br />
          <br />
          {/* <Curriculum /> */}
          <br />
          <Programs source="HOME_PAGE_PROGRAMS_SECTION" />
          <BlogSection />
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default HomePageView;
