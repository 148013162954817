import Courses from "../../components/courses/Courses";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import Programs from "../../components/programs/Programs";
import "./CoursesView.css";

function CoursesView() {
  return (
    <>
      <Header />
      <div className="coursesContainer">
        <Programs source="PROGRAMS_PAGE" />
      </div>
      <Footer />
    </>
  );
}

export default CoursesView;
