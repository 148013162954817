const EnggStats = () => {
  return (
    <section className="section background-dark spdtb">
      <div className="container">
        <div className="heading heading-alway-white align-center">
          <h2 className="heading-title size-l">Why Software Developer?</h2>
        </div>
        <div className="block-counter align-center layout-01">
          <div className="row">
            <div className="col-lg-4 col-6x">
              <div className="counter-box color-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <g fill="none" fill-rule="evenodd">
                    <g fill="#0057FC" fill-rule="nonzero">
                      <g>
                        <g>
                          <g>
                            <g>
                              <path
                                d="M2.822 24L19.98 6.848 19.98 22.658 24 22.585 24 0 1.411 0 1.411 3.95 17.158 4.023 0 21.175z"
                                transform="translate(-351.000000, -3886.000000) translate(0.000000, 900.000000) translate(0.000000, 2768.000000) translate(135.000000, 188.000000) translate(56.000000, 24.000000) translate(160.000000, 6.000000)"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                <div className="sub">Jobs</div>
                <div className="number">
                  <span className="counter">90</span>
                  <span className="suffix">L</span>
                </div>
                <div className="title">
                  New SDE jobs to be <br />
                  created by 2025
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-6x">
              <div className="counter-box color-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <g fill="none" fill-rule="evenodd">
                    <g fill="#0057FC" fill-rule="nonzero">
                      <g>
                        <g>
                          <g>
                            <g>
                              <path
                                d="M2.822 24L19.98 6.848 19.98 22.658 24 22.585 24 0 1.411 0 1.411 3.95 17.158 4.023 0 21.175z"
                                transform="translate(-351.000000, -3886.000000) translate(0.000000, 900.000000) translate(0.000000, 2768.000000) translate(135.000000, 188.000000) translate(56.000000, 24.000000) translate(160.000000, 6.000000)"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                <div className="sub">Companies</div>
                <div className="number">
                  <span className="counter">25</span>
                  <span className="suffix">K</span>
                </div>
                <div className="title">
                  Tech startups in India & 3.5 companies are born every day
                </div>
                {/* <div className="title">Tech Startups in India</div> */}
              </div>
            </div>
            <div className="col-lg-4 col-6x">
              <div className="counter-box color-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <g fill="none" fill-rule="evenodd">
                    <g fill="#0057FC" fill-rule="nonzero">
                      <g>
                        <g>
                          <g>
                            <g>
                              <path
                                d="M2.822 24L19.98 6.848 19.98 22.658 24 22.585 24 0 1.411 0 1.411 3.95 17.158 4.023 0 21.175z"
                                transform="translate(-351.000000, -3886.000000) translate(0.000000, 900.000000) translate(0.000000, 2768.000000) translate(135.000000, 188.000000) translate(56.000000, 24.000000) translate(160.000000, 6.000000)"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                <div className="sub">Hiring</div>
                <div className="number">
                  <span className="counter">67</span>
                  <span className="suffix">%</span>
                </div>
                {/* <div className="title">Employers expressed intent to hire freshers during 2023-24</div> */}
                <div className="title">
                  Employers expressed intent to hire freshers during 2023-24
                </div>
              </div>
            </div>
            {/* <div className="col-lg-3 col-6">
                            <div className="counter-box color-white">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <g fill="none" fill-rule="evenodd">
                                        <g fill="#0057FC" fill-rule="nonzero">
                                            <g>
                                                <g>
                                                    <g>
                                                        <g>
                                                            <path d="M2.822 24L19.98 6.848 19.98 22.658 24 22.585 24 0 1.411 0 1.411 3.95 17.158 4.023 0 21.175z" transform="translate(-351.000000, -3886.000000) translate(0.000000, 900.000000) translate(0.000000, 2768.000000) translate(135.000000, 188.000000) translate(56.000000, 24.000000) translate(160.000000, 6.000000)"/>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                                <div className="sub">Bookings</div>
                                <div className="number"><span className="counter">11,000</span></div>
                                <div className="title">United in 2019</div>
                            </div>
                        </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default EnggStats;
