import React, { useEffect, useState } from "react";
import "./ProgramCard.css";
import html5Icon from "../../resources/icons/icons8-html.svg";
import css3Icon from "../../resources/icons/icons8-css.svg";
import jsGif from "../../resources/icons/icons8-js.gif";
import reactIcon from "../../resources/icons/icons8-react.png";
import nodeIcon from "../../resources/icons/icons8-nodejs.svg";
import mongoIcon from "../../resources/icons/mongo_db.png";
import Badge from "react-bootstrap/Badge";
import chatGptIcon from "../../resources/icons/icons8-chatgpt.svg";
import copilotIcon from "../../resources/icons/icons8-windows-copilot.svg";
import awsIcon from "../../resources/icons/icons8-amazon-web-services.svg";
import hourClassIcon from "../../resources/icons/icons8-hourglass.gif";
import hourClassIconColor from "../../resources/icons/icons8-hourglass_color.gif";
import RequestCallbackModal from "../common/RequestCallbackModal";

const ProgramCard = (props) => {
  const { programDetails } = props;

  const [showApplyNowModal, setShowApplyNowModal] = useState(false);

  const handleClose = () => {
    setShowApplyNowModal(false);
  };
  

  const openApplyNowForm = () => {
    if(!!programDetails.applyNowFormLink) {
        window.open(programDetails.applyNowFormLink, '_blank');
    }
  }

  const openBuyNowClick = () => {
    if(!!programDetails.buyNowLink) {
      window.location.href = programDetails.buyNowLink;
  }
  }

  return (
    <>
      <div className="program-card-section">
        <div className="live-section">
          <Badge
            bg="#f97316"
            style={{
              backgroundColor: "#f97316",
              display: "flex",
              alignItems: "center",
            }}
          >
            {programDetails.badgeText}
          </Badge>
        </div>
        <div className="card-body">
          <div>
            <h3 style={{ color: "#1565d8" }}>{programDetails.programTitle}</h3>
          </div>
          <div className="eligible-strip">
            <div className="color-strip">{programDetails.eligibleInfo}</div>
          </div>
          <div className="program-description">
            {programDetails.description}
          </div>
          <div className="subjects-section">
            {programDetails.subjectIcons.map((subjectIcon) => {
              return (
                <div>
                  <img
                    width={subjectIcon.width}
                    height={subjectIcon.height}
                    src={subjectIcon.icon}
                    alt={subjectIcon.alt}
                  />
                </div>
              );
            })}
          </div>
          <div className="program-details-section">
            <div>
              <div className="program-details-header">PROGRAM STARTS ON</div>
              <div className="program-details-value">
                {programDetails.startDate}
              </div>
            </div>
            <div className="more-info-section">
              <div>
                <img
                  width={programDetails.bottomInfo.icon.width}
                  height={programDetails.bottomInfo.icon.height}
                  src={programDetails.bottomInfo.icon.icon}
                  alt=""
                />
              </div>
              <div className="more-info-text">
                <span
                  dangerouslySetInnerHTML={{
                    __html: programDetails.bottomInfo.description,
                  }}
                />
              </div>
            </div>
          </div>
          <div className='button-section'>
                {
                    programDetails.detailsUrl &&
                    <div className='btn-border' onClick={() => {window.location.href = programDetails.detailsUrl}}>
                        Know More
                    </div>
                }
                {
                    programDetails.applyNowFormLink &&
                    <div className='btn-border' onClick={openApplyNowForm}>
                        Register Now
                    </div>
                }
                {
                  programDetails.requestCallbackId && 
                  <div className='btn-background' onClick={() => setShowApplyNowModal(true)}>
                    Request Callback
                  </div>
                }
                {
                  programDetails.buyNowLink &&
                  <div className='btn-background' onClick={openBuyNowClick}>
                    Program Details
                  </div>
                }
            </div>
        </div>
      </div>
      {showApplyNowModal && (
        <RequestCallbackModal
          show={showApplyNowModal}
          handleClose={handleClose}
          programType={programDetails.requestCallbackId}
          source={props.source}
        />
      )}
    </>
  );
};

export default ProgramCard;
