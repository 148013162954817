import MiniProgram from "./MiniProgram";
import {
  faCalendarDays,
  faUnlock,
  faGraduationCap,
  faSuitcase,
  faCircleCheck,
  faUnlockAlt,
} from "@fortawesome/free-solid-svg-icons";

const Programs = (props) => {
  const miniPrograms = [
    {
        imageUrl: 'https://i.pinimg.com/564x/fa/e8/62/fae862fff4f6100d000a1c01c4030db0.jpg',
        title: 'AI Ready Full Stack Developer Program',
        description: "Dive into the future of tech startups, where learning is a vibrant journey crafted 'Of, For & By' the startup techies. Join us as we fuse passion with purpose, paving paths to tech roles in India and beyond. Let's code the future together, fuelled by boundless curiosity and collaborative spirit",
        features: [
            {
                featureText: "<b>Front End : </b> ReactJS, NextJS",
            },
            {
                featureText: "<b>Back End : </b> NodeJS,  ExpressJS, MongoDB ",
            },
            {
                featureText: "<b> AI : </b> GitHub CoPilot, GPT API's, ChatGPT",
            },
            {
                featureText: "<b> Cloud: </b> AWS; <b>Laser Focus</b> on DS & Algo",
            }   
        ],
        outcomes: [
            {
                outcomeText: 'Startup Simulation Training (2-4 Weeks) with Pilot Projects of Tech Startups',
            },
            {
                outcomeText: 'AI First Training Approach (Tools & LLM API Integrations)',
            },
            {
                outcomeText: 'IMP (Instructor, Mentor, Problem Solver) Teaching System',
            }
        ],
        details: [
            {
              icon: faCalendarDays,
              header: 'Duration',
              value: '6 months'
            },
            {
              icon: faGraduationCap,
              header: 'Fee',
              value: 'RS 4,000 / Month'
            },
            {
              icon: faSuitcase,
              header: 'Placements',
              value: '100% Support (Till Placement)'
            },
            {
                icon: faUnlockAlt,
                header: 'Trial Session',
                value: '14 days'
            },
        ],
        learnMoreUrl: '/program/software-development-full-stack-program',
        applyNowId: 'FULL_STACK_PAID_COURSE_MAR_2024',
        applyNowFormLink: 'https://docs.google.com/forms/d/e/1FAIpQLSec8vrYzq_nv7RzY4UezMrmDbXSRL-HIzHFXR36ZJRrKEKK4Q/viewform'
    },
  ];

  return (
    <div
      style={{ display: "flex", flexDirection: "column", gap: "24px" }}
      className="container"
      id="programs_section"
    >
      <h1 style={{ textAlign: "center" }}>Explore Our Programs</h1>
      <p style={{ textAlign: "center" }}>
        Learn the skills to be a “AI Ready” developer and Land a job at tech startups in India & Abroad
      </p>
      {miniPrograms.map((miniProgram) => {
        return <MiniProgram miniProgram={miniProgram} source={props.source} />;
      })}
    </div>
  );
};

export default Programs;
