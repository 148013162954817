import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { useEffect, useState } from "react";
import RestClient from "../../../services/RestClient";
import TinyMCEEditor from "../../tinyMceEditor/TinyMCEEditor";
import "./CreateEditJob.css";
import { getISTTimeFormatFromUTCTimeString } from "../../../utils/TimeUtils";

function CreateEditJob({ jobId, jobData, isEdit }) {
  const [createJobRequest, setCreateJobRequest] = useState(jobData || {});
  const [apiMessage, setApiMessage] = useState(null);

  useEffect(() => {
    setCreateJobRequest({ ...jobData });
  }, [jobId, jobData, isEdit]);

  const handleCreateJobFormChange = (event, field) => {
    switch (field) {
      case "expiryDateTime":
        setCreateJobRequest({
          ...createJobRequest,
          [field]: new Date(event.target.value).toISOString() // as we need to save UTC Date
        });
        break;
      case "skills":
        setCreateJobRequest({
          ...createJobRequest,
          [field]: event.target.value?.split(","),
        });
        break;
      case "isInternship":
        setCreateJobRequest({
          ...createJobRequest,
          isInternship: event.target.checked
        });
        break;
      default:
        setCreateJobRequest({
          ...createJobRequest,
          [field]: event.target.value,
        });
        break;
    }
  };

  const handleJobDescriptionFormEditor = (data) => {
    setCreateJobRequest({ ...createJobRequest, description: data });
  };

  const updateJob = (event) => {
    event.target.reset();
    console.log(createJobRequest);
    setApiMessage("Upating. Please hold on....");
    RestClient.doPut(`jobs/${jobId}`, createJobRequest)
      .then((response) => {
        if (response.status === 200) {
          console.log(response);
          // setCreateBlogRequest({});
          setApiMessage("Hurray!!! Job updated");
        }
      })
      .catch((err) => {
        console.log(err);
        setApiMessage("Something went wrong. Please try again");
      });
    setTimeout(() => {
      setApiMessage(null);
    }, 5000);
  };

  const createJob = (event) => {
    console.log(createJobRequest);
    setApiMessage("Creating. Please hold on....");
    RestClient.doPost("jobs", createJobRequest)
      .then((response) => {
        if (response.status === 200) {
          console.log(response);
          event.target.reset();
          setCreateJobRequest({});
          setApiMessage("Hurray!!! Job created");
        }
      })
      .catch((err) => {
        console.log(err);
        setApiMessage("Something went wrong. Please try again");
      });
    setTimeout(() => {
      setApiMessage(null);
    }, 5000);
  };

  const handleSubmit = (event) => {
    console.log("handling submit");
    event.preventDefault();
    if (isEdit) {
      return updateJob(event);
    }

    return createJob(event);
  };

  return (
    <>
      <div className="createEditJobDiv" style={{ marginTop: "80px" }}>
        <h3
          style={{
            textDecoration: "underline",
            textAlign: "center",
            marginBottom: "10px",
          }}
        >
          {isEdit ? 'Update Job': 'Create Job' }
        </h3>
        <Form
          className="pr-10"
          onSubmit={handleSubmit}
          style={{
            border: "0px solid gray",
            padding: "10px",
            borderRadius: "5px",
            marginTop: "-20px",
          }}
        >
          <Row>
            <Col lg={9}>
              <Form.Group className="mb-3" controlId="title">
                <Form.Label>Job Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ex: Software Engineer - 1"
                  value={createJobRequest.title}
                  onChange={(e) => handleCreateJobFormChange(e, "title")}
                />
              </Form.Group>
            </Col>
            <Col style={{display: 'flex', alignItems: 'flex-end'}}>
              <Form.Group className="mb-3" controlId="title">
                <Form.Check
                  type="checkbox"
                  label="Is Internship ?"
                  checked={createJobRequest.isInternship}
                  onChange={(e) => handleCreateJobFormChange(e, "isInternship")}
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="mb-3" controlId="companyName">
            <Form.Label>Company Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ex: Career Charge"
              value={createJobRequest.companyName}
              onChange={(e) => handleCreateJobFormChange(e, "companyName")}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="companyLogoUrl">
            <Form.Label>Company Logo URL</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ex: https://careercharge.in/static/media/logo1.d29b285b3ba567f668e0.png"
              value={createJobRequest.companyLogoUrl}
              onChange={(e) => handleCreateJobFormChange(e, "companyLogoUrl")}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="location">
            <Form.Label>Location</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ex: Bangalore"
              value={createJobRequest.location}
              onChange={(e) => handleCreateJobFormChange(e, "location")}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEduation">
            <Form.Label>Skills (separated by commas)</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ex: Java, Python, Machine Learning, AI..."
              value={createJobRequest.skills && createJobRequest.skills.join(",")}
              onChange={(e) => handleCreateJobFormChange(e, "skills")}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="applyUrl">
            <Form.Label>Application URL</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ex: https://careercharge.in/jobs"
              value={createJobRequest.applyUrl}
              onChange={(e) => handleCreateJobFormChange(e, "applyUrl")}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="expiryDateTime">
            <Form.Label>Expiry Date</Form.Label>
            <Form.Control
              type="datetime-local"
              placeholder="2024-07-16T03:42:25.563Z"
              value={createJobRequest.expiryDateTime && getISTTimeFormatFromUTCTimeString(createJobRequest.expiryDateTime)}
              onChange={(e) => handleCreateJobFormChange(e, "expiryDateTime")}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="shortDescription">
            <Form.Label>Short Description</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ex: Career Charge is looking for software engineer who is self-learner, quick grasper,... (atleast 2 lines content)..."
              value={createJobRequest.shortDescription}
              onChange={(e) => handleCreateJobFormChange(e, "shortDescription")}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="description">
            <Form.Label>Full Details</Form.Label>
            <TinyMCEEditor
              content={createJobRequest.description}
              handleCallback={handleJobDescriptionFormEditor}
            />
          </Form.Group>
          <div style={{ textAlign: "center", paddingTop: "10px" }}>
            {apiMessage && <p>{apiMessage}</p>}
            <Button variant="secondary" size="lg" type="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
}

export default CreateEditJob;
