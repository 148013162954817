import "./MiniProgram.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import RequestCallbackModal from "../common/RequestCallbackModal";
const MiniProgram = (props) => {
  const { miniProgram } = props;
  const [showApplyNowModal, setShowApplyNowModal] = useState(false);

  const handleClose = () => {
    setShowApplyNowModal(false);
  };

  const openApplyNowForm = () => {
    if(!!miniProgram.applyNowFormLink) {
        window.open(miniProgram.applyNowFormLink, '_blank');
    }
}

  return (
    <>
      <div className="mp_section">
        <div className="mp_left_panel">
          <img className="mp_image" src={miniProgram.imageUrl} />
          <h5 className="mp_header">{miniProgram.title}</h5>
          <p className="mp_description">{miniProgram.description}</p>
          <div className="mp_features">
            {miniProgram.features.map((feature) => {
              return (
                <div className="mp_feature_item">
                  <FontAwesomeIcon
                    className="mp_features_icon"
                    icon={faCircleCheck}
                  />
                  <div className="mp_feature_text">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: feature.featureText
                      }}
                  />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="mp_outcomes">
            {miniProgram.outcomes.map((outcome) => {
              return (
                <div className="mp_outcome_item">{outcome.outcomeText}</div>
              );
            })}
          </div>
        </div>
        <div className="mp_right_panel">
          <div className="mp_details_section">
            {miniProgram.details.map((details) => {
              return (
                <div className="mp_details_item">
                  <div className="mp_details_item_image">
                    <FontAwesomeIcon
                      className="mp_details_item_icon"
                      icon={details.icon}
                    />
                  </div>
                  <div className="mp_details_item_content">
                    <div className="mp_details_item_header">
                      {details.header}
                    </div>
                    <div className="mp_details_item_value">{details.value}</div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="mp_buttons">
            <div
              className="learn_more_section"
              onClick={() =>
                (window.location.href = `${miniProgram.learnMoreUrl}`)
              }
            >
              Program Details &nbsp;
              <svg
                style={{ height: "16px", marginTop: "3px" }}
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="angle-right"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 256 512"
              >
                <path
                  fill="currentColor"
                  d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"
                ></path>
              </svg>
            </div>
            <div
              className="apply_now_section"
              onClick={openApplyNowForm}
            >
              {/* need to have modal here */}
              Apply now &nbsp;
              <svg
                style={{ height: "16px", marginTop: "3px" }}
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="angle-right"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 256 512"
              >
                <path
                  fill="currentColor"
                  d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
      {showApplyNowModal && (
        <RequestCallbackModal
          show={showApplyNowModal}
          handleClose={handleClose}
          programType={miniProgram.applyNowId || "NO_PROGRAM_TYPE"}
          source={props.source}
        />
      )}
    </>
  );
};

export default MiniProgram;

{
  /* <div className='mp_feature_item'>
<FontAwesomeIcon className='mp_features_icon' icon={faCircleCheck} />
<div className='mp_feature_text'>
    Complete Web Automation using using tools like Selenium, Webdriver, Xpath
</div>
</div>
<div className='mp_feature_item'>
<FontAwesomeIcon className='mp_features_icon' icon={faCircleCheck} />
<div className='mp_feature_text'>
    Complete Web Automation using using tools like Selenium, Webdriver, Xpath
</div>
</div>
<div className='mp_feature_item'>
<FontAwesomeIcon className='mp_features_icon' icon={faCircleCheck} />
<div className='mp_feature_text'>
    Complete Web Automation using using tools like Selenium, Webdriver, Xpath
</div>
</div>
<div className='mp_feature_item'>
<FontAwesomeIcon className='mp_features_icon' icon={faCircleCheck} />
<div className='mp_feature_text'>
    Complete Web Automation using using tools like Selenium, Webdriver, Xpath
</div>
</div> */
}

{
  /* <div className='mp_outcome_item'>
3+ large scale professional test automation projects
</div>
<div className='mp_outcome_item'>
45+ mini-learning modules
</div>
<div className='mp_outcome_item'>
Learn Java, Selenium, TestNG, Cucumber, API, Perf Testing and more
</div> */
}

{
  /* <div className="mp_details_item">
<div className='mp_details_item_image'>
    <FontAwesomeIcon className='mp_details_item_icon' icon={faUnlockAlt} />
</div>
<div className='mp_details_item_content'>
    <div className='mp_details_item_header'>
        Trial Session
    </div>
    <div className='mp_details_item_value'>
        Free
    </div>
</div>
</div>
<div className="mp_details_item">
<div className='mp_details_item_image'>
    <FontAwesomeIcon className='mp_details_item_icon' icon={faCalendarDays} />
</div>
<div className='mp_details_item_content'>
    <div className='mp_details_item_header'>
        Duration
    </div>
    <div className='mp_details_item_value'>
        9 months
    </div>
</div>
</div>
<div className="mp_details_item">
<div className='mp_details_item_image'>
<FontAwesomeIcon className='mp_details_item_icon' icon={faGraduationCap} />
</div>
<div className='mp_details_item_content'>
    <div className='mp_details_item_header'>
        Scholarships
    </div>
    <div className='mp_details_item_value'>
        Assured Scholarships
    </div>
</div>
</div>
<div className="mp_details_item">
<div className='mp_details_item_image'>
    <FontAwesomeIcon className='mp_details_item_icon' icon={faSuitcase} />
</div>
<div className='mp_details_item_content'>
    <div className='mp_details_item_header'>
        Career Services
    </div>
    <div className='mp_details_item_value'>
        Placement Guarantee
    </div>
</div>
</div> */
}
