import React from "react";
import placeholderImage from "../../resources/images/placeholder.svg";

const reviews = [
  {
    name: "Ayeesha Shaik",
    review:
      "It was a fabulous hands-on experience with “live”ly classes. Great Lecturer+Learner Compatibility & Live Coding sessions, which helps for beginners like us, to start & learn from zero, improve communication, face off individual fears & developing projects",
    imagePath: "",
    altText: "Ayeesha Shaik",
  },
  {
    name: "Jitendra R",
    review:
      "The training team actively clarifies doubts and supports our progress. Engaging quizzes, assignments, and group projects make learning enjoyable, with the friendly atmosphere, which I loved most.",
    imagePath: "",
    altText: "Jitendra R",
  },
  {
    name: "Swathi M",
    review:
      "A wonderful training period and gained real-world insights into the software industry.  Now, I can strongly say that I will create any type of website. In addition to their friendly nature, the way they communicated with us is great and happy to have been a part of it.",
    imagePath: "",
    altText: "Swathi M",
  },
  {
    name: "Sasi Priya K",
    review:
      "The online classes & live coding sessions were excellent, providing valuable insights. The challenging daily assessments enhanced my learning experience. Collaborating on GitHub and learning to maintain my LinkedIn account were added bonuses.",
    imagePath: "",
    altText: "Sasi Priya K",
  }
];

function Testimonials() {
  return (
    <div className="bg-[#2c3e50] py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center text-white">REVIEWS</h2>
        <p className="mt-4 text-2xl text-center font-semibold text-white">
          Limited but Genuine
        </p>
        <div className="mt-8 flex space-x-6 overflow-auto hide-scrollbar">
          {reviews.map((review, index) => (
            <div
              key={index}
              className="rounded-lg border shadow-sm min-w-[300px] bg-white text-black"
              data-v0-t="card"
            >
              <div className="p-4">
                <div className="flex items-center">
                  <span className="relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full">
                    <img
                      className="aspect-square h-full w-full"
                      src={placeholderImage}
                      alt={review.altText}
                    />
                  </span>
                  <h3 className="ml-4 text-lg font-semibold">{review.name}</h3>
                </div>
                <p className="mt-2 text-sm">{review.review}</p>
              </div>
            </div>
          ))}
        </div>
        {/* <div className="mt-8 text-center">
          <button className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2 text-black border-black bg-white">
            View More Reviews
          </button>
        </div> */}
      </div>
    </div>
  );
}

export default Testimonials;
