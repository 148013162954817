import { Button, Container, Row } from "react-bootstrap";
import Header from "../../../components/header/Header";
import AdminJobCard from "../../../components/jobCard/AdminJobCard";
import { useEffect, useRef, useState } from "react";
import RestClient from "../../../services/RestClient";

const AdminListJobsView  = () => {

    const [jobs, setJobs] = useState([])
    const lastFetchedJobId = useRef(null);
    const [loadMore, setLoadMore] = useState(true)

    const PARAMS_SIZE = 10

    const setLoadMoreDetails = (response) => {
        lastFetchedJobId.current = response.data?.jobs?.length ? response.data.jobs[response.data?.jobs?.length - 1]['_id'] : null
        if(response.data?.jobs?.length < PARAMS_SIZE) {
            setLoadMore(false)
        }
    }
    
    const handleLoadMore = () => {
        if(lastFetchedJobId.current) {
            const params = {
                size: PARAMS_SIZE,
                lastFetchedId: lastFetchedJobId.current
            }
            RestClient.doGet("jobs", params)
            .then((response) => {
              console.log(response);
              if (response.status === 200) {
                setJobs([...jobs, ...response.data?.jobs]);
                setLoadMoreDetails(response)
              } else {
                console.log("something went wrong. ", response);
              }
            })
            .catch((err) => {
              console.log("Error while calling get blogs api. ", err);
            });
        }
    }

    useEffect(() => {
        const params = {
            size: PARAMS_SIZE
        }
        RestClient.doGet("jobs", params)
          .then((response) => {
            console.log(response);
            if (response.status === 200) {
              setJobs(response.data?.jobs);
              setLoadMoreDetails(response)
            } else {
              console.log("something went wrong. ", response);
            }
          })
          .catch((err) => {
            console.log("Error while calling get blogs api. ", err);
          });
    }, []);

    const openCreateJobPage = () => {
        window.location.href = '/admin/jobs/create';
    }

    return (
        <>
          <Header />
          <Container>
            <div style={{display: "flex", justifyContent: 'right', marginTop: '20px'}}>
                <Button onClick={openCreateJobPage}>Create Job</Button>
            </div>
            <div style={{ marginTop: "40px" }}>
                <h3
                style={{
                    textAlign: "center",
                    textDecoration: "",
                    marginBottom: "20px",
                }}
                >
                Job Opportunities - Admin Portal 
                </h3>
                <hr />
                <Container>
                <Row style={{ gap: "20px" }} className="justify-content-center">
                    {
                        jobs.map(job => {
                            return <AdminJobCard jobData={job}/>
                        })
                    }
                </Row>
                </Container>
            </div>
                <div style={{textAlign: 'center', marginTop: '20px'}}>
                    {loadMore && <Button onClick={handleLoadMore}>Load More</Button>}
                </div>
          </Container>
          {/* <Footer /> */}
        </>
      );
}

export default AdminListJobsView