import img1 from "../../resources/images/hr-01.png";
import img2 from "../../resources/images/nft-06.webp";
import img3 from "../../resources/images/homepage.jpeg";
import { useEffect, useState } from "react";
import RestClient from "../../services/RestClient";
import BlogCard from "../blogcard/BlogCard";

const BlogSection = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const params = {
      lastFetchedBlogId: null,
      size: 3, // this should be backend
    };
    RestClient.doGet("blogs", params)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setBlogs(response.data?.blogs);
        } else {
          console.log("something went wrong. ", response);
        }
      })
      .catch((err) => {
        console.log("Error while calling get blogs api. ", err);
        setBlogs([]);
      });
  }, []);
  return (
    <>
      {blogs && (
        <section className="section spdt">
          <div className="block-blog">
            <div className="container">
              <div className="heading align-center">
                <h2 className="heading-title size-l">From our blog</h2>
              </div>
              <div className="row">
                {blogs.map((blog) => {
                  return (
                    <div className="col-lg-4 col-sm-12">
                      <BlogCard
                        title={blog.title}
                        description={blog.description}
                        thumbnailUrl={blog.thumbnailUrl}
                        blogId={blog._id}
                        author={blog.author}
                        creationTimeMillis={new Date(
                          blog.creationTime,
                        ).getTime()}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default BlogSection;
