import maheshkarthu from '../../resources/images/mentors/mahesh-kartu.jpeg'
import santosh from '../../resources/images/mentors/santosh.jpeg'

import {FaTwitter, FaLinkedinIn, FaGithub, FaLink} from 'react-icons/fa'
const instructorsData = [
  {
    name: "Mahesh Karthu",
    position: "CTO",
    company: "Amotion.ai",
    imageUrl: maheshkarthu,
    links: {
      linkedin: "https://www.linkedin.com/in/maheshkarthu/",
      twitter: "#",
      github: "#",
      portfolio: "#",
    },
  },
  {
    name: "Santosh Baswa",
    position: "Security @ R&D",
    company: "Tiktok Singapore",
    imageUrl: santosh,
    links: {
      linkedin: "https://www.linkedin.com/in/santhoshbaswa/",
      twitter: "#",
      github: "#",
      portfolio: "#",
    },
  }
];

export default function Advisors() {
  return (
    <div className="bg-[#2c3e50] py-4">
          <br></br>
<br></br>
      <div className="text-center">
        <h2 className="text-white text-4xl font-bold">
          Meet Our{" "}
          <span className="underline decoration-dotted underline-offset-2 decoration-orange-500 text-orange-500">
            
          </span>{" "}
          Advisors
        </h2>
      </div>
      <div className="container flex justify-start md:justify-center overflow-x-auto py-4 space-x-6 px-4 hide-scrollbar max-w-4xl">
        {instructorsData.map((instructor, index) => (
          <div key={index} className="flex-none w-60 min-w-[15rem] bg-white rounded-lg shadow-md p-3 relative">
            <div className="relative">
              <img
                alt="Mentor"
                className="h-40 w-full object-cover rounded-lg"
                src={instructor.imageUrl}
                style={{
                  aspectRatio: "240/160",
                  objectFit: "cover",
                }}
              />
              <div className="absolute bottom-0 right-0 mb-1 mr-1 flex space-x-1">
                {instructor.links.linkedin && instructor.links.linkedin !== "#" && <a href={instructor.links.linkedin} className="bg-white rounded-full p-1"><FaLinkedinIn className="h-5 w-5 text-gray-800" /></a>}
                {instructor.links.twitter && instructor.links.twitter !== "#" && <a href={instructor.links.twitter} className="bg-white rounded-full p-1"><FaTwitter className="h-5 w-5 text-gray-800" /></a>}
                {instructor.links.github && instructor.links.github !== "#" && <a href={instructor.links.github} className="bg-white rounded-full p-1"><FaGithub className="h-5 w-5 text-gray-800" /></a>}
                {instructor.links.portfolio && instructor.links.portfolio !== "#" && <a href={instructor.links.portfolio} className="bg-white rounded-full p-1"><FaLink className="h-5 w-5 text-gray-800" /></a>}
              </div>
            </div>
            <div className="mt-4">
              <h3 className="text-lg font-semibold">{instructor.name}</h3>
              <p className="text-sm text-gray-600">{instructor.position}, {instructor.company}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}