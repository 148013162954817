import CreateEditJob from "../../../components/admin/job/CreateEditJob";
import Header from "../../../components/header/Header";

function CreateJobView() {
  return (
    <>
      <Header />
      <CreateEditJob />
    </>
  );
}

export default CreateJobView;
