import { Button, Container, Modal } from "react-bootstrap"
import "./JobDetailsModal.css"

const JobDetailsModal = (props) => {

    const {jobData} = props

    const handleApplyNow = () => {
        window.open(jobData.applyUrl, '_blank');
        props.handleClose()
    }

    return (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            className="view-job-details-modal"
            dialogClassName="view-job-details-modal-dialog d-flex justify-content-center align-items-center"
            tabindex="-1"
        > 
        {/* <Modal.Dialog style={{}} className="callback-modal"> */}
            <Modal.Header
                closeButton
                style={{ padding: "10px 30px 0px", paddingBottom: "0px" }}
            >
                <div>
                    <h3>{jobData.companyName} - {jobData.title}</h3>
                    <p>Job available in {jobData.location}</p>
                </div>
            </Modal.Header>
            <Modal.Body style={{ paddingBottom: "5px" }} className="view-details-modal-body">
                <Container fluid>
                <div className="jobDetails">
                    <div dangerouslySetInnerHTML={{ __html: jobData.description }} />
                </div>
                </Container>
            </Modal.Body>
            <Modal.Footer style={{display: 'flex', justifyContent: 'center'}}>
                <Button onClick={handleApplyNow}>Apply Now</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default JobDetailsModal