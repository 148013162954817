import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import JobCard from "../../components/jobCard/JobCard";
import RestClient from "../../services/RestClient";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import './JobsListing.css';

const JobsListingView  = () => {

    const [jobs, setJobs] = useState([])
    const lastFetchedJobId = useRef(null);
    const [loadMore, setLoadMore] = useState(true)
    const [filterOptions, setFilterOptions] = useState({type: "all"})

    const PARAMS_SIZE = 10

    const setLoadMoreDetails = (response) => {
        lastFetchedJobId.current = response.data?.jobs?.length ? response.data.jobs[response.data?.jobs?.length - 1]['_id'] : null
        if(response.data?.jobs?.length < PARAMS_SIZE) {
            setLoadMore(false)
        }else {
            setLoadMore(true);
        }
    }

    const handleFilterOptions = (event) => {
        setFilterOptions({...filterOptions, type: event.target.value})
    }
    
    const handleLoadMore = () => {
        if(lastFetchedJobId.current) {
            const params = {
                size: PARAMS_SIZE,
                lastFetchedId: lastFetchedJobId.current
            }
            RestClient.doGet("jobs", params)
            .then((response) => {
              console.log(response);
              if (response.status === 200) {
                setJobs([...jobs, ...response.data?.jobs]);
                setLoadMoreDetails(response)
              } else {
                console.log("something went wrong. ", response);
              }
            })
            .catch((err) => {
              console.log("Error while calling get blogs api. ", err);
            });
        }
    }

    // useEffect(() => {
    //     const params = {
    //         size: PARAMS_SIZE
    //     }
    //     RestClient.doGet("jobs", params)
    //       .then((response) => {
    //         console.log(response);
    //         if (response.status === 200) {
    //           setJobs(response.data?.jobs);
    //           setLoadMoreDetails(response)
    //         } else {
    //           console.log("something went wrong. ", response);
    //         }
    //       })
    //       .catch((err) => {
    //         console.log("Error while calling get blogs api. ", err);
    //       });
    // }, []);

    useEffect(() => {
        const params = {
            size: PARAMS_SIZE,
            type: filterOptions.type
        }
        RestClient.doGet("jobs", params)
          .then((response) => {
            console.log(response);
            if (response.status === 200) {
              setJobs(response.data?.jobs);
              setLoadMoreDetails(response)
            } else {
              console.log("something went wrong. ", response);
            }
          })
          .catch((err) => {
            console.log("Error while calling get blogs api. ", err);
          });
    }, [filterOptions]);

    return (
        <>
          <Header />
          <Container>
            <div style={{ marginTop: "40px" }}>
                <h3
                    style={{
                        textAlign: "center",
                        textDecoration: "",
                        marginBottom: "20px",
                    }}
                >
                Job Opportunities
                </h3>
                <hr />
                <Container>
                    <Row>
                        <Col lg={2}>
                            <div>
                                <p style={{fontWeight: '500'}}>Filter Options</p>
                                <Form.Check
                                    type="radio"
                                    label="Internships"
                                    name="filterOptions"
                                    id="internships"
                                    value={"internship"}
                                    onChange={e => handleFilterOptions(e)}
                                    checked={filterOptions.type === "internship"}
                                />
                                <Form.Check
                                    type="radio"
                                    label="All"
                                    name="filterOptions"
                                    id="all"
                                    value={"all"}
                                    onChange={e => handleFilterOptions(e)}
                                    checked={filterOptions.type === "all"}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
                                {
                                    jobs.map(job => {
                                        return <JobCard jobData={job}/>
                                    })
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div style={{textAlign: 'center', marginTop: '20px'}}>
                {loadMore && <Button className="load-more-button" onClick={handleLoadMore}>Load More</Button>}
            </div>
          </Container>
          <br /><br />
          <Footer />
        </>
      );
}

export default JobsListingView