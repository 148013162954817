import { useState } from "react"
import "./AdminJobCard.css"
import JobDetailsModal from "../common/job/JobDetailsModal"

const JobCard = ({jobData}) => {

    const [showViewJobModal, setShowViewJobModal] = useState(false)
    const [viewJobData, setViewJobData] = useState(null)

    const handleViewJob = () => {
        setViewJobData(jobData)
        setShowViewJobModal(true)
    }

    const handleCloseViewJobModal = () => {
        setViewJobData(null)
        setShowViewJobModal(false)
    }

    return (
        <>
        <div className="job-card">
            <div className="job-details">
                <div className="job-title">{`${jobData.companyName} - ${jobData.title}`}</div>
                <span className="job-location">Job available in {jobData.location}</span>
                <div className="job-description text-ellipsis-2-lines">
                    {jobData.shortDescription}
                </div>
                <div className="job-skills">
                    {
                        jobData.skills?.map(skill => {
                            return <div className="job-skills-item">{skill}</div>
                        })
                    }
                </div>
            </div>
            <div className="logo-section">
                <div className="logo-div">
                    <img className="company-logo" alt="company logo" src={jobData.companyLogoUrl} width={"80px"} height={"80px"} />
                </div>
                <div className="view-button-section">
                    <button className="view-button" onClick={handleViewJob}>View »</button>
                </div>
            </div>
        </div>
        {showViewJobModal && 
            <JobDetailsModal show={showViewJobModal} handleClose={handleCloseViewJobModal} jobData={viewJobData}/>
        }
        </>
    )
}

export default JobCard