import roadMapWebp from "../../resources/images/nft-06.webp";

const RoadMap = () => {
  return (
    <section
      className="section background-yellow has-shape spdtb overflow-hidden"
      style={{ backgroundColor: "lightblue" }}
    >
      <div className="shape-top">
        {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1440"
                height="69"
                viewBox="0 0 1440 69"
            >
                <path
                    fill="var(--sala-neutral-white, $neutral_white)"
                    fillRule="nonzero"
                    d="M0 232L735 180 662.78899 232 1440 217.5 1440 249 0 249z"
                    transform="translate(0 -1424) translate(0 1244) rotate(180 720 214.5)"
                ></path>
            </svg> */}
      </div>
      <div className="container">
        <div className="heading heading-alway-dark align-left mb32">
          {/* <div className="heading-sub">roadmap</div> */}
          {/* <h2 className="heading-title size-l">Taking Charge of your Career</h2> */}
          <h2 className="heading-title size-l">
            Your Journey at Career Charge?
          </h2>
          <p>Start your tech career by testing your skills</p>
        </div>
        <div className="row row-full">
          <div className="col-lg-6">
            <div className="block-timeline layout-01">
              <div className="item">
                <div className="date">Step-1</div>
                <h4 className="title">Skill Test</h4>
                <div className="desc">
                  Take the skill test to know where you stand in the tech world
                </div>
              </div>
              <div className="item">
                <div className="date">Step-2</div>
                <h4 className="title">Onboarding Interview</h4>
                <div className="desc">
                  We want to know the real you and see the mutual fit and set
                  the right expectations
                </div>
              </div>
              <div className="item">
                <div className="date">Step-3</div>
                <h4 className="title">Training</h4>
                <div className="desc">
                  Your training starts with more problem solving approach and
                  ends with startup simulation training
                </div>
              </div>
              <div className="item">
                <div className="date">Step-4</div>
                <h4 className="title">Placement</h4>
                <div className="desc">
                  We will congratulate you on cracking a job in amazing tech
                  startup
                </div>
              </div>
              {/* <div className="item">
                                <div className="date">Step-4</div>
                                <h4 className="title">Get Ready for Interview</h4>
                                <div className="desc">Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit</div>
                            </div>
                            <div className="item">
                                <div className="date">Step-5</div>
                                <h4 className="title">Crack your Dream Job!!</h4>
                                <div className="desc">Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit</div>
                            </div> */}
            </div>
            <div
              style={{
                fontSize: "15px",
                fontWeight: "bold",
                marginTop: "20px",
                letterSpacing: "0px",
              }}
            >
              When you're in doubt, you'll always regret later! Dust your doubts
              by hitting skill test &nbsp;
              <div
                style={{ marginTop: 10 }}
                className="button fullfield xs-mb10"
                title="Attempt Now"
              >
                <a
                  style={{ textDecoration: "none", color: "white" }}
                  href="https://docs.google.com/forms/u/2/d/1dBspiTxqDRuqvZ8GfSQteWc5H27fc1UuZWSlmV_snbA/edit"
                  target="_blank"
                >
                  Attempt Skill Test Now
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-6 d-none d-lg-block col-full-right">
            <div className="pdr50">
              {/* <img
                className="wow animate__fadeInRight"
                src={roadMapWebp}
                alt="Banner"
              /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="shape-bottom">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1440"
          height="69"
          viewBox="0 0 1440 69"
        >
          <path
            fill="var(--sala-neutral-white, $neutral_white)"
            fillRule="nonzero"
            d="M0 232L735 180 662.78899 232 1440 217.5 1440 249 0 249z"
            transform="translate(0 -1424) translate(0 1244) rotate(180 720 214.5)"
          ></path>
        </svg>
      </div>
    </section>
  );
};

export default RoadMap;
