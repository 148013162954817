import { Container } from "react-bootstrap";
import Header from "../../components/header/Header";
import "./TermsAndConditions.css";
import Footer from "../../components/footer/Footer";

const TermsAndCondtions = () => {
  return (
    <>
      <Header />
      <div
        style={{
          marginTop: "40px",
          display: "block",
          maxWidth: "1100px",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "100px",
        }}
      >
        <Container>
          <div
            className="termsAndConditions"
            style={{
              fontFamily:
                'SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace',
              fontSize: "14px",
            }}
          >
            <div>
              <div className="tcTitle">
                Career Charge: Terms and Conditions{" "}
              </div>
              <div className="tcTitle">Effective Date: 19/07/2013</div>
              <div>
                Welcome to Career Charge! We are delighted to have you on board
                as a fresh learner in the upskillment segment. Before you begin
                using our platform and services, please take a moment to read
                and understand the following terms and conditions that govern
                your use of our website, mobile application, and any other
                services we provide (collectively referred to as the
                "Platform").
              </div>
              <div className="tcTitle">1. Acceptance of Terms </div>
              <div>
                By accessing or using the Platform, you agree to be bound by
                these Terms and Conditions and any additional terms and policies
                incorporated herein by reference. If you do not agree to these
                terms, please refrain from using our services.
              </div>
              <div className="tcTitle">2. Services Offered</div>
              <div>
                Career Charge offers a range of upskilling courses and learning
                materials for freshers. The courses may include but are not
                limited to tutorials, assessments, quizzes, forums, and other
                educational resources (the "services").
              </div>
              <div className="tcTitle">3. User Accounts</div>
              <div>
                3.1 Account Creation: To access certain features of the
                Platform, you must create a user account. You agree to provide
                accurate, complete, and up-to-date information during the
                registration process. You are solely responsible for maintaining
                the confidentiality of your account credentials.
              </div>
              <div>
                3.2 Account Usage: You agree not to share your account
                credentials with anyone else. You are responsible for any
                activity that occurs under your account, whether or not
                authorized by you. If you suspect unauthorized access to your
                account, you must notify us immediately.
              </div>
              <div className="tcTitle">4. User Guidelines</div>
              <div>
                4.1 Prohibited Conduct: While using the Platform, you must not
                engage in any of the following activities:
                <ol type="a">
                  <li>
                    Violate any applicable laws, regulations, or third-party
                    rights.
                  </li>
                  <li>
                    Post, upload, or transmit any harmful, defamatory,
                    offensive, or inappropriate content.
                  </li>
                  <li>
                    Use the Platform for any illegal or unauthorized purpose.
                  </li>
                  <li>
                    Attempt to bypass or interfere with our security measures.
                  </li>
                  <li>
                    Engage in any activity that could disrupt the proper
                    functioning of the Platform or adversely affect other users'
                    experiences.
                  </li>
                </ol>
              </div>
              <div>
                4.2 Course Completion: Some courses may require completion
                within a specified timeframe. You acknowledge that failure to
                meet these requirements may impact your access to course
                materials or certification.
              </div>
              <div className="tcTitle">5. Intellectual Property</div>
              <div>
                5.1 Content Ownership: All content provided through the
                Platform, including courses, videos, text, and graphics, are the
                intellectual property of Career Charge or its licensors. You may
                not use, reproduce, or distribute any content without obtaining
                explicit permission.
              </div>
              <div>
                5.2 User Content: By submitting any content to the Platform,
                such as comments or forum posts, you grant Career Charge a
                non-exclusive, worldwide, royalty-free license to use, modify,
                and display the content for the purpose of providing and
                improving the Services.
              </div>
              <div className="tcTitle">6. Payment and Refunds</div>
              <div>
                6.1 Pricing: The prices for courses and other services are
                clearly stated on the Platform. Career Charge reserves the right
                to modify pricing at any time, but such changes will not affect
                purchases made before the update.
              </div>
              <div>
                6.2 Payment: Payment for courses and services is to be made
                through the designated payment methods provided on the Platform.
                You agree to pay all applicable fees in full.
              </div>
              <div>
                6.3 Refunds: Refund policies for courses and services are
                available on the Platform. Please review these policies before
                making any purchases. Refunds, if applicable, will be processed
                according to the stated guidelines.
              </div>
              <div className="tcTitle">7. Limitation of Liability</div>
              <div>
                7.1 Disclaimer: The Platform and Services are provided on an "as
                is" basis, and Career Charge makes no warranties, express or
                implied, regarding the accuracy, reliability, or suitability of
                the content. We do not guarantee that the Platform will be
                uninterrupted, error-free, or secure.
              </div>
              <div>
                7.2 Indemnification: You agree to indemnify and hold Career
                Charge harmless from any claims, damages, losses, or expenses
                arising from your use of the Platform or violation of these
                Terms and Conditions.
              </div>
              <div className="tcTitle">8. Termination</div>
              <div>
                Career Charge reserves the right to terminate or suspend your
                access to the Platform at any time, with or without cause, and
                without prior notice.
              </div>
              <div className="tcTitle">9. Modification of Terms</div>
              <div>
                We may update these Terms and Conditions from time to time, and
                the updated version will be posted on the Platform. Your
                continued use of the Platform after the changes become effective
                constitutes your acceptance of the revised terms.
              </div>
              <div className="tcTitle">10. Governing Law and Jurisdiction</div>
              <div>
                These Terms and Conditions shall be governed by and construed in
                accordance with the laws of India, without regard to its
                conflict of laws principles. Any disputes arising under or in
                connection with these terms shall be subject to the exclusive
                jurisdiction of the courts in Bangalore.
              </div>
              <div className="tcTitle">
                Thank you for choosing Career Charge. We are excited to be part
                of your upskillment journey! If you have any questions or
                concerns about these terms, please contact us at
                contact@careercharge.in.
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default TermsAndCondtions;
