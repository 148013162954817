import { Container } from "react-bootstrap";
import Header from "../../components/header/Header";
import "./PrivacyPolicy.css";
import Footer from "../../components/footer/Footer";

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <div
        style={{
          marginTop: "40px",
          display: "block",
          maxWidth: "1100px",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "100px",
        }}
      >
        <Container>
          <div
            className="privacyPolicy"
            style={{
              fontFamily:
                'SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace',
              fontSize: "14px",
            }}
          >
            <div>
              <div className="ppTitle">Career Charge: Privacy Policy </div>
              <div className="ppTitle">Effective Date: 19/07/2013</div>
              <div>
                At Career Charge, we are committed to protecting your privacy
                and ensuring the security of your personal information. This
                Privacy Policy outlines how we collect, use, disclose, and
                safeguard the data you provide to us while using our website,
                mobile application, and other services (collectively referred to
                as the "Platform"). By accessing or using the Platform, you
                consent to the practices described in this policy.
              </div>
              <div className="ppTitle">1. Information We Collect </div>
              <div>
                1.1 Personal Information: When you register for an account or
                use our Services, we may collect personal information, including
                but not limited to your name, email address, contact number, and
                educational background. We may also collect payment information
                when you make purchases on the Platform.
              </div>
              <div>
                1.2 Usage Data: We gather non-personal information about your
                interactions with the Platform, such as your IP address, browser
                type, device information, and the pages you visit. This data is
                collected through cookies and similar technologies.
              </div>
              <div>
                1.3 Course Progress and Performance: As you use the Platform, we
                may collect data about your course progress, quiz scores, and
                other educational performance metrics to provide personalized
                learning experiences.
              </div>
              <div className="ppTitle">2. How We Use Your Information</div>
              <div>
                2.1 Providing Services: We use your personal information to
                provide the Services you request, such as course enrollment,
                progress tracking, and customer support.
              </div>
              <div>
                2.2 Communication: We may use your contact details to send you
                important updates, notifications, and promotional material
                related to the Platform. You can opt-out of marketing
                communications at any time.
              </div>
              <div>
                2.3 Analytics and Improvements: We use Usage Data to analyze
                user behavior, preferences, and trends to improve the Platform's
                performance, content, and user experience.
              </div>
              <div>
                2.4 Legal Compliance: In certain cases, we may be required to
                use and disclose your information to comply with legal
                obligations, respond to legal claims, or protect our rights,
                property, and safety or that of others.
              </div>
              <div className="ppTitle">3. Data Sharing and Disclosure</div>
              <div>
                3.1 Third-Party Service Providers: We may share your personal
                information with third-party service providers who assist us in
                delivering the Services, such as payment processors, customer
                support, and analytics tools. These providers are contractually
                obligated to handle your data securely and only for the purposes
                specified.
              </div>
              <div>
                3.2 Business Transactions: In the event of a merger,
                acquisition, or sale of all or a portion of our assets, your
                personal information may be transferred to the acquiring entity.
              </div>
              <div>
                3.3 Legal Requirements: We may disclose your information if
                required to do so by law or in response to valid requests by
                public authorities.
              </div>
              <div className="ppTitle">4. Data Security</div>
              <div>
                4.1 We implement reasonable security measures to protect your
                personal information from unauthorized access, disclosure,
                alteration, and destruction.
                {/* <ol type="a">
                                <li>Violate any applicable laws, regulations, or third-party rights.</li>
                                <li>Post, upload, or transmit any harmful, defamatory, offensive, or inappropriate content.</li>
                                <li>Use the Platform for any illegal or unauthorized purpose.</li>
                                <li>Attempt to bypass or interfere with our security measures.</li>
                                <li>Engage in any activity that could disrupt the proper functioning of the Platform or adversely affect other users' experiences.</li>
                            </ol> */}
              </div>
              <div>
                4.2 While we take reasonable precautions, no data transmission
                over the internet or electronic storage method is 100% secure.
                Therefore, we cannot guarantee absolute security.
              </div>
              <div className="ppTitle">5. Your Choices</div>
              <div>
                5.1 Account Settings: You can review and update your account
                information by logging into your user profile on the Platform.
              </div>
              <div>
                5.2 Cookies and Tracking Technologies: Most web browsers allow
                you to control cookies through their settings. You may opt-out
                of certain tracking technologies used on the Platform.
              </div>
              <div>
                5.3 Marketing Communications: You can unsubscribe from our
                marketing emails by following the instructions provided in the
                communication.
              </div>
              <div className="ppTitle">6. Children's Privacy</div>
              <div>
                Our Services are not intended for children under the age of 13.
                If you are under 13, please do not provide any personal
                information on the Platform. If you believe we may have
                collected personal information from a child under 13, please
                contact us immediately, and we will take appropriate steps to
                delete such data.
              </div>
              <div className="ppTitle">7. Changes to this Privacy Policy</div>
              <div>
                We may update this Privacy Policy from time to time, and the
                revised version will be posted on the Platform. Your continued
                use of the Platform after the changes become effective
                constitutes your acceptance of the revised policy.
              </div>
              <div className="ppTitle">8. Contact Us</div>
              <div>
                If you have any questions or concerns about this Privacy Policy
                or our data practices, please contact us at
                contact@careercharge.in.
              </div>
              <div className="ppTitle">
                By using the Platform, you acknowledge that you have read,
                understood, and agree to the practices described in this Privacy
                Policy.
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
