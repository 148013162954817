import React, { useState } from "react";
import RestClient from "../../services/RestClient";

const ProgramHeroSection = (props) => {

  const [apiMessage, setApiMessage] = useState("");
  const [mandatoryFieldsError, setMandatoryFieldsError] = useState("");
  const [restCallLoading, setRestCallLoading] = useState(false);
  const [formData, setFormData] = useState({});

  const mandatoryFields = {"name": "string", "phoneNumber": "string", "email": "string"};

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const constructRequestCallbackPayload = () => {
    const payload = {};
    payload["name"] = formData.name;
    payload["phoneNumber"] = formData.phoneNumber;
    payload["email"] = formData.email;
    payload["source"] = props.source || "NO_SOURCE";
    payload["programType"] = props.programType || "NO_PROGRAM_TYPE";

    return payload;
  };

  const handleSubmit = (event) => {
    console.log(formData);
    const payload = constructRequestCallbackPayload();
    console.log(payload);
    const errors = validateRequestCallbackPayload(payload);
    console.log(errors);
    event.preventDefault();
    if (!!errors && !!errors.length) {
      setMandatoryFieldsError(errors.join(", ") + " are missing");
      return;
    } else {
      setMandatoryFieldsError("");
    }
    // event.target.reset();
    // setApiMessage("Submitting....");
    setRestCallLoading(true);
    RestClient.doPost("programs/callbackRequest", payload)
      .then((response) => {
        if (response.status === 200) {
          console.log(response);
          setFormData({});
          setApiMessage("Great! Our expert will contact you soon");
          event.target.reset();
        }
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
        setApiMessage("Something went wrong. Please try again");
      })
      .finally(() => {
        console.log("hi");
        setRestCallLoading(false);
      });
    setTimeout(() => {
      setApiMessage(null);
    }, 5000);
  };

  const validateRequestCallbackPayload = (payload) => {
    const errors = [];
    if (!payload) {
      return errors;
    }
    for (let field of Object.keys(mandatoryFields)) {
      switch (mandatoryFields[field]) {
        case "string":
          if (!payload[field]) {
            errors.push(field);
          }
          break;
        case "array":
          if (!payload[field] || !payload[field].length) {
            errors.push(field);
          }
          break;
        default:
          if (!payload[field]) {
            errors.push(field);
          }
          break;
      }
    }

    return errors;
  };

  return (
    <section className="bg-[#2c3e50] py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center justify-between gap-8">
        <div className="md:w-3/6">
          <h1 className="text-4xl font-bold text-white mb-6">
            AI Ready Full Stack Developer Program
          </h1>
          <p className="text-lg text-gray-300 mb-8">
            Learn the skills and land a high paying job in the AI era. Get into
            tech startups in India & Abroad guided “of, for & by” the startup
            guys.
          </p>
          <div className="grid grid-cols-2 gap-4">
            <div className="bg-white rounded-lg shadow-lg p-3">
              <h2 className="text-lg font-bold text-gray-700 mb-2">
                Program Duration
              </h2>
              <p className="text-gray-700 ">6 Months</p>
              <hr></hr>

              <p className="text-sm  text-gray-700 mb-2">
                Classes starts from 13th, June
              </p>

              {/* <div className="flex flex-wrap gap-2">
              <div className="inline-flex items-center rounded-full whitespace-nowrap border px-2.5 py-0.5 w-fit text-xs font-semibold text-gray-800 transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-primary-foreground hover:bg-primary/80">
                HTML
              </div>
              <div className="inline-flex items-center rounded-full whitespace-nowrap border px-2.5 py-0.5 w-fit text-xs font-semibold text-gray-800 transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-primary-foreground hover:bg-primary/80">
                CSS
              </div>
              <div className="inline-flex items-center rounded-full whitespace-nowrap border px-2.5 py-0.5 w-fit text-xs font-semibold text-gray-800 transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-primary-foreground hover:bg-primary/80">
                Javascript
              </div>
              <div className="inline-flex items-center rounded-full whitespace-nowrap border px-2.5 py-0.5 w-fit text-xs font-semibold text-gray-800 transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent  text-primary-foreground hover:bg-primary/80">
                React
              </div>
              <div className="inline-flex items-center rounded-full whitespace-nowrap border px-2.5 py-0.5 w-fit text-xs font-semibold text-gray-800 transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent  text-primary-foreground hover:bg-primary/80">
                Node.js
              </div>
              <div className="inline-flex items-center rounded-full whitespace-nowrap border px-2.5 py-0.5 w-fit text-xs font-semibold text-gray-800 transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent  text-primary-foreground hover:bg-primary/80">
                MongoDB
              </div>
            </div> */}
            </div>
            <div className="bg-white rounded-lg shadow-lg p-3">
              <h2 className="text-lg font-bold text-gray-700 mb-2">
                Program Fee
              </h2>
              <p className="text-gray-600">
                Total Fee: ₹25000 <s>₹40000</s>
              </p>
              <hr></hr>

              <p className="text-sm text-gray-600">
                EMI Options Available (4K/ Month)
              </p>
            </div>
          </div>
        </div>
        <div className="md:w-2/5 sm:w-4/6 bg-white rounded-lg shadow-lg p-8">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Name
              </label>
              <input
                className="h-10 rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 mt-1 block w-full"
                id="name"
                placeholder="Name"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <input
                className="h-10 rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 mt-1 block w-full"
                id="email"
                placeholder="Email"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div>
              <label
                htmlFor="phone"
                className="block text-sm font-medium text-gray-700"
              >
                Phone
              </label>
              <input
                className="h-10 rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 mt-1 block w-full"
                id="phone"
                placeholder="Phone"
                type="tel"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
              />
            </div>
            {mandatoryFieldsError && (
                <div>
                  <span style={{ fontSize: "14px", color: "red" }}>
                    *{mandatoryFieldsError}
                  </span>
                </div>
              )}
              {apiMessage && (
                <div style={{ marginLeft: "0px" }}>
                  <span style={{ fontSize: "14px", color: "#2c3e50" }}>
                    {apiMessage}
                  </span>
                </div>
              )}
            <button type="submit" disabled={restCallLoading} className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-white hover:bg-primary/90 h-10 px-4 py-2 w-full">
              Start Your Journey
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ProgramHeroSection;
