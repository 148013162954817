import React, { useState } from "react";
import CurriculumDownloadModal from "../common/CurriculumDownloadModal";

const DownloadCurriculum = () => {
  const [showDownloadModal, setShowDownloadModal] = useState(false);

  const handleShowDownloadModalClose = () => {
    setShowDownloadModal(false);
  };


  return (
    <div className="bg-[#2c3e50]">
    <div className="  mx-auto max-w-4xl justify-center items-center p-8">
      <div className="bg-blue-200  rounded-lg p-6 flex flex-col items-center">
        <p className="text-xl text-black mb-4 text-center">
        Learn & Land - A high paying tech job at startups in India & Abroad. Download our detailed curriculum and unlock the gateway to your tech future
        </p>
        <div className="flex justify-center">
          <button
            onClick={() => setShowDownloadModal(true)}
            className="justify-center text-white whitespace-nowrap rounded-md text-sm font-medium ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 h-10 bg-blue-700 px-8 py-3 flex items-center hover:bg-blue-600 transition-colors duration-200">
            Download Curriculum
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="ml-2 w-4 h-4"
            >
              <path d="m9 18 6-6-6-6"></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <CurriculumDownloadModal
      show={showDownloadModal}
      handleClose={handleShowDownloadModalClose}
    />
    </div>
  );
};

export default DownloadCurriculum;
