import CreateEditBlog from "../../components/admin/CreateEditBlog";
import Header from "../../components/header/Header";

function CreateBlogView() {
  return (
    <>
      <Header />
      <CreateEditBlog />
    </>
  );
}

export default CreateBlogView;
