import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Logo1 from "../../resources/images/logo1.png";

const Header = () => {
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        className="bg-[#F8F9FC] border-b-0 pt-3"
      >
        <Container className="border-b-0 mt-0">
          <Navbar.Brand
            href="/"
            className="border-0 font-sans font-normal flex items-center"
          >
            <img src={Logo1} width="30px" className="mr-2" alt="Logo" />
            Career Charge
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" className="ml-10">
            <Nav className="me-auto text-lg font-sans font-normal">
              <Nav.Link href="/about-us">About</Nav.Link>
              <Nav.Link href="/programs">Programs</Nav.Link>
              <Nav.Link href="/blogs">Blog</Nav.Link>
              <Nav.Link href="/jobs">Startup Jobs</Nav.Link>
              <Nav.Link eventKey={2} href="/contact-us">
                Contact
              </Nav.Link>
            </Nav>
            <Nav>
              <Nav.Link eventKey={2} href="/hire-with-us">
                <div
                  className="registerButton fullfield xs-mb10"
                  title="Get Started"
                >
                  Hire With Us
                </div>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
