export default function CourseCurriculum() {
  return (
    <div className="bg-[#2c3e50]">
      <div className="max-w-6xl mx-auto p-3 bg-[#2c3e50] text-[#f5f5f5]">
        <h1 className="text-3xl font-extrabold tracking-tight text-white sm:text-4xl text-center">
        AI Ready Full Stack Developer - Program Curriculum
        </h1>
        <p className="mb-8 text-base text-[#bdc3c7] text-center">
        Experience a comprehensive curriculum, meticulously designed to transform you into an AI Ready Full Stack Developer, armed with the expertise for tomorrow's tech challenges

        </p>
        <div className="space-y-3">
        <details className="p-3 border border-[#ecf0f1] rounded-md bg-[#34495e]">
            <summary className="text-2xl font-semibold cursor-pointer text-[#ecf0f1]">
              Programming foundations using JavaScript
              <ChevronDownIcon className="float-right text-[#ecf0f1]" />
            </summary>
                <div className="mt-4 space-y-3 bg-[#2c3e50] p-3 rounded-md border border-[#ecf0f1]">
                  <ul className="list-disc pl-5 mt-2 space-y-2 text-sm text-[#ecf0f1]">
                    <li>Introduction to JavaScript</li>
                    <li>Data Types</li>
                    <li>Operators</li>
                    <li>Conditional Statements</li>
                    <li>Loops</li>
                    <li>Functions</li>
                    <li>Arrays, Objects, Strings and operations </li>
                  </ul>
                </div>
          </details>
          <details className="p-3 border border-[#ecf0f1] rounded-md bg-[#34495e]">
            <summary className="text-2xl font-semibold cursor-pointer text-[#ecf0f1]">
              Developer Essentials
              <ChevronDownIcon className="float-right text-[#ecf0f1]" />
            </summary>
                <div className="mt-4 space-y-3 bg-[#2c3e50] p-3 rounded-md border border-[#ecf0f1]">
                  <ul className="list-disc pl-5 mt-2 space-y-2 text-sm text-[#ecf0f1]">
                    <li>Linux & Command line fundamentals</li>
                    <li>Git</li>
                  </ul>
                </div>
          </details>
          <details className="p-3 border border-[#ecf0f1] rounded-md bg-[#34495e]">
            <summary className="text-2xl font-semibold cursor-pointer text-[#ecf0f1]">
            Data structures and algo using JavaScript
              <ChevronDownIcon className="float-right text-[#ecf0f1]" />
            </summary>
                <div className="mt-4 space-y-3 bg-[#2c3e50] p-3 rounded-md border border-[#ecf0f1]">
                  <ul className="list-disc pl-5 mt-2 space-y-2 text-sm text-[#ecf0f1]">
                    <li>Space and Time complexity</li>
                    <li>Arrays & Strings</li>
                    <li>Sorting, Searching</li>
                    <li>LinkedList</li>
                    <li>Stack, Queue</li>
                    <li>HashMap</li>
                    <li>Recursion</li>
                    <li>Trees, Binary Tree, Binary Search Tree</li>
                    <li>BFS, DFS</li>
                  </ul>
                </div>
          </details>
          <details className="p-3 border border-[#ecf0f1] rounded-md bg-[#34495e]">
            <summary className="text-2xl font-semibold cursor-pointer text-[#ecf0f1]">
              Full Stack Course - Node.Js + React.Js + MongoDB + Express.Js
              <ChevronDownIcon className="float-right text-[#ecf0f1]" />
            </summary>
            <div className="mt-4 space-y-3">
              <details className="p-3 border border-[#ecf0f1] rounded-md bg-[#2c3e50]">
                <summary className="font-semibold cursor-pointer text-xl text-[#ecf0f1]">
                  HTML
                  <ChevronDownIcon className="float-right text-[#ecf0f1]" />
                </summary>
                <div className="mt-4 space-y-3 bg-[#2c3e50] p-3 rounded-md border border-[#ecf0f1]">
                  <ul className="list-disc pl-5 mt-2 space-y-2 text-sm text-[#ecf0f1]">
                    <li>Introduction</li>
                    <li>Tags & Elements</li>
                    <li>Attributes</li>
                    <li>Document Structure</li>
                    <li>Headings & Paragraphs</li>
                    <li>Links & Anchor Tags</li>
                    <li>Images & Image Tags</li>
                    <li>Lists</li>
                    <li>Tables</li>
                    <li>Forms</li>
                  </ul>
                </div>
              </details>
              <details className="p-3 border border-[#ecf0f1] rounded-md bg-[#2c3e50]">
                <summary className="font-semibold cursor-pointer text-xl text-[#ecf0f1]">
                  CSS
                  <ChevronDownIcon className="float-right text-[#ecf0f1]" />
                </summary>
                <div className="mt-4 space-y-3 bg-[#2c3e50] p-3 rounded-md border border-[#ecf0f1]">
                  <ul className="list-disc pl-5 mt-2 space-y-2 text-sm text-[#ecf0f1]">
                    <li>Introduction</li>
                    <li>Selectors</li>
                    <li>Properties & Values</li>
                    <li>Box Model</li>
                    <li>Layout</li>
                    <li>Typography</li>
                    <li>Colors & Background</li>
                    <li>Responsive Design</li>
                    <li>Transitions and Animations</li>
                    <li>External Stylesheets</li>
                  </ul>
                </div>
              </details>
              <details className="p-3 border border-[#ecf0f1] rounded-md bg-[#2c3e50]">
                <summary className="font-semibold cursor-pointer text-xl text-[#ecf0f1]">
                  JavaScript Deep Dive
                  <ChevronDownIcon className="float-right text-[#ecf0f1]" />
                </summary>
                <div className="mt-4 space-y-3 bg-[#2c3e50] p-3 rounded-md border border-[#ecf0f1]">
                  <ul className="list-disc pl-5 mt-2 space-y-2 text-sm text-[#ecf0f1]">
                    <li>DOM Manipulation</li>
                    <li>Form Handling</li>
                    <li>Callbacks & Event Listeners</li>
                    <li>Async, Await, Promise</li>
                    <li>ES6 Features</li>
                  </ul>
                </div>
              </details>
              <details className="p-3 border border-[#ecf0f1] rounded-md bg-[#2c3e50]">
                <summary className="font-semibold cursor-pointer text-xl text-[#ecf0f1]">
                  React JavaScript
                  <ChevronDownIcon className="float-right text-[#ecf0f1]" />
                </summary>
                <div className="mt-4 space-y-3 bg-[#2c3e50] p-3 rounded-md border border-[#ecf0f1]">
                  <ul className="list-disc pl-5 mt-2 space-y-2 text-sm text-[#ecf0f1]">
                    <li>Introduction</li>
                    <li>Components</li>
                    <li>JavaScriptX</li>
                    <li>Props & State</li>
                    <li>Lifecycle Methods</li>
                    <li>Functional Components Hooks</li>
                    <li>Event Handling</li>
                    <li>Conditional Rendering</li>
                    <li>Lists and Keys</li>
                    <li>Component Composition</li>
                  </ul>
                </div>
              </details>
              <details className="p-3 border border-[#ecf0f1] rounded-md bg-[#2c3e50]">
                <summary className="font-semibold cursor-pointer text-xl text-[#ecf0f1]">
                  NodeJavaScript + Mongo
                  <ChevronDownIcon className="float-right text-[#ecf0f1]" />
                </summary>
                <div className="mt-4 space-y-3 bg-[#2c3e50] p-3 rounded-md border border-[#ecf0f1]">
                  <ul className="list-disc pl-5 mt-2 space-y-2 text-sm text-[#ecf0f1]">
                    <li>NodeJavaScript Setup</li>
                    <li>Event Loop</li>
                    <li>Express.JavaScript for Restful APIs</li>
                    <li>Routing and Middleware</li>
                    <li>Authetication and Authorization using JWT</li>
                    <li>Error Handling Middleware</li>
                    <li>Mongo Setup</li>
                    <li>Models & Schema using mongoose</li>
                    <li>CRUD Operations</li>
                    <li>Testing & Debugging</li>
                    <li>Deployment</li>
                  </ul>
                </div>
              </details>
              <details className="p-3 border border-[#ecf0f1] rounded-md bg-[#2c3e50]">
                <summary className="font-semibold cursor-pointer text-xl text-[#ecf0f1]">
                  Addons
                  <ChevronDownIcon className="float-right text-[#ecf0f1]" />
                </summary>
                <div className="mt-4 space-y-3 bg-[#2c3e50] p-3 rounded-md border border-[#ecf0f1]">
                  <ul className="list-disc pl-5 mt-2 space-y-2 text-sm text-[#ecf0f1]">
                    <li>SQL Basics</li>
                    <li>AWS Basics</li>
                    <li>AI Developer Tools - ChatGPT, Copilot</li>
                  </ul>
                </div>
              </details>
            </div>
          </details>
          <details className="p-3 border border-[#ecf0f1] rounded-md bg-[#34495e]">
            <summary className="text-2xl font-semibold cursor-pointer text-[#ecf0f1]">
              Projects and deployment
              <ChevronDownIcon className="float-right text-[#ecf0f1]" />
            </summary>
                <div className="mt-4 space-y-3 bg-[#2c3e50] p-3 rounded-md border border-[#ecf0f1]">
                  <ul className="list-disc pl-5 mt-2 space-y-2 text-sm text-[#ecf0f1]">
                    <li>Portfolio Website</li>
                    <li>ShopKart: A Full Stack E-Commerce App</li>
                    <li>EventBuzz: A Full Stack Event Booking Experience</li>
                    <li>ShortenIt: A Full Stack URL Shortening Service</li>
                  </ul>
                </div>
          </details>
          <details className="p-3 border border-[#ecf0f1] rounded-md bg-[#34495e]">
            <summary className="text-2xl font-semibold cursor-pointer text-[#ecf0f1]">
              Interview Ready Preparation
              <ChevronDownIcon className="float-right text-[#ecf0f1]" />
            </summary>
                <div className="mt-4 space-y-3 bg-[#2c3e50] p-3 rounded-md border border-[#ecf0f1]">
                  <ul className="list-disc pl-5 mt-2 space-y-2 text-sm text-[#ecf0f1]">
                    <li>Resume 1-1</li>
                    <li>Portfolio</li>
                    <li>Mock Interviews 1-1</li>
                    <li>Guidance from Industry Professionals</li>
                  </ul>
                  {/* <p className="text-sm mt-2 text-[#ecf0f1]">
                    Build a responsive Food Munch website using HTML5, CSS3 and
                    Bootstrap Grid System.
                  </p> */}
                </div>
          </details>
        </div>
      </div>
    </div>
  );
}

function ChevronDownIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m6 9 6 6 6-6" />
    </svg>
  );
}
