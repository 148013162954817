import placeHolderImage from '../../resources/images/placeholder.svg'
import mentorSivaGamidi from '../../resources/images/mentors/siva-gamidi.jpeg'
import mentorSivaThota from '../../resources/images/mentors/siva-thota.jpg'
import mentorKasi from '../../resources/images/mentors/kasireddy.jpeg'
import mentorKiran from '../../resources/images/mentors/kiran.jpeg'
import mentorAjay from '../../resources/images/mentors/ajay.jpeg'


import {FaTwitter, FaLinkedinIn, FaGithub, FaLink} from 'react-icons/fa'
const instructorsData = [
  {
    name: "Ajay Kobireddy",
    position: "Founder",
    company: "Career Charge",
    imageUrl: mentorAjay,
    links: {
      linkedin: "https://www.linkedin.com/in/ajayreddykobireddygari",
      twitter: "#",
      github: "#",
      portfolio: "#",
    },
  },
  {
    name: "Kasi Reddy",
    position: "Software Engineer",
    company: "SimpliSafe",
    imageUrl: mentorKasi,
    links: {
      linkedin: "https://in.linkedin.com/in/kasireddyduggempudi",
      twitter: "#",
      github: "#",
      portfolio: "#",
    },
  },
  {
    name: "Kiran",
    position: "Software Engineer",
    company: "GooseFX",
    imageUrl: mentorKiran,
    links: {
      linkedin: "#",
      twitter: "#",
      github: "#",
      portfolio: "https://kiranmuddam.com/",
    },
  },
  {
    name: "Siva Nagaraju",
    position: "Software Engineer",
    company: "Upland Software",
    imageUrl: mentorSivaGamidi,
    links: {
      linkedin: "https://in.linkedin.com/in/siva-nagaraju",
      twitter: "#",
      github: "#",
      portfolio: "#",
    },
  },
  {
    name: "Siva Thota",
    position: "Software Engineer",
    company: "Zepto",
    imageUrl: mentorSivaThota,
    links: {
      linkedin: "https://in.linkedin.com/in/siva140191",
      twitter: "#",
      github: "#",
      portfolio: "#",
    },
  }
];

export default function Instructors() {
  return (
    <div className="bg-[#2c3e50] py-4">
          <br></br>
<br></br>
      <div className="text-center">
        <h2 className="text-white text-4xl font-bold">
          Meet Our{" "}
          <span className="underline decoration-dotted underline-offset-2 decoration-orange-500 text-orange-500">
            IMP
          </span>{" "}
          People
        </h2>
      </div>
      <div className="container flex overflow-x-auto py-4 space-x-6 px-4 hide-scrollbar max-w-4xl">
        {instructorsData.map((instructor, index) => (
          <div key={index} className="flex-none w-60 min-w-[15rem] bg-white rounded-lg shadow-md p-3 relative">
            <div className="relative">
              <img
                alt="Mentor"
                className="h-40 w-full object-cover rounded-lg"
                src={instructor.imageUrl}
                style={{
                  aspectRatio: "240/160",
                  objectFit: "cover",
                }}
              />
              <div className="absolute bottom-0 right-0 mb-1 mr-1 flex space-x-1">
                {instructor.links.linkedin && instructor.links.linkedin !== "#" && <a href={instructor.links.linkedin} className="bg-white rounded-full p-1"><FaLinkedinIn className="h-5 w-5 text-gray-800" /></a>}
                {instructor.links.twitter && instructor.links.twitter !== "#" && <a href={instructor.links.twitter} className="bg-white rounded-full p-1"><FaTwitter className="h-5 w-5 text-gray-800" /></a>}
                {instructor.links.github && instructor.links.github !== "#" && <a href={instructor.links.github} className="bg-white rounded-full p-1"><FaGithub className="h-5 w-5 text-gray-800" /></a>}
                {instructor.links.portfolio && instructor.links.portfolio !== "#" && <a href={instructor.links.portfolio} className="bg-white rounded-full p-1"><FaLink className="h-5 w-5 text-gray-800" /></a>}
              </div>
            </div>
            <div className="mt-4">
              <h3 className="text-lg font-semibold">{instructor.name}</h3>
              <p className="text-sm text-gray-600">{instructor.position}, {instructor.company}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}