import React from "react";
import placeholderImg from "../../resources/images/placeholder.svg";
import googleLogo from "../../resources/images/google.svg";
import amotion from "../../resources/images/partners/amotion.png";
import aignit from "../../resources/images/partners/aignit.png";
import brochill from "../../resources/images/partners/brochill.png";
import insightq from "../../resources/images/partners/insightq.png";
import Mee from "../../resources/images/partners/Mee.png";
import MeeNews from "../../resources/images/partners/MeeNews.png";
import titanisu from "../../resources/images/partners/titanisu.png";

const HiringPartners = () => {
  const companies = [
    { name: "Amotion.ai", logo: amotion },
    { name: "Aignit", logo: aignit },
    { name: "Brochill", logo: brochill },
    { name: "InsightQ", logo: insightq },
    { name: "Mee", logo: Mee },
    { name: "Mee News", logo: MeeNews },
    { name: "Titanisu", logo: titanisu }
  ];

  return (
    <div className="bg-[#2c3e50] py-16 text-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-semibold text-center mb-12">
          Tech companies that trust our talent
        </h2>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-8 items-center mb-8">
          {companies.map((company, index) => (
            <img
              key={index}
              src={company.logo}
              alt={`${company.name} Logo`}
              className="mx-auto"
              width="100"
              height="24"
              style={{ aspectRatio: "200 / 100", objectFit: "contain" }}
              title={company.name}
            />
          ))}
        </div>
        {/* <div className="text-center text-lg mb-8">and many more...</div> */}
        <div className="flex justify-center">
         <a href="/hire-with-us" target="blank" style={{textDecoration: 0}}> <button className="justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 h-10 bg-white text-[#2c3e50] px-8 py-3 flex items-center hover:bg-[#87cefa] transition-colors duration-200">
            Start Hiring
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="ml-2 w-4 h-4"
            >
              <path d="m9 18 6-6-6-6"></path>
            </svg>
          </button></a>
        </div>
        <div className="text-center text-sm mt-4">
          Submit your requirements & our team will get in touch
        </div>
      </div>
    </div>
  );
};

export default HiringPartners;
