import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faLinkedin,
  faDiscord
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  const [newsLetterEmail, setNewsLetterEmail] = useState(null);
  const [newsLetterMessage, setNewsLetterMessage] = useState(null);

  const handleNewsLetterEmailChange = (e) => {
    setNewsLetterEmail(e.target.value);
  };

  const handleNewsLetterSubmit = () => {
    if (!!newsLetterEmail) {
      setNewsLetterMessage("Great!! You will receive our updates...");
    } else {
      setNewsLetterMessage("Please enter email");
    }
    setTimeout(() => {
      setNewsLetterMessage(null);
    }, 2000);
  };
  return (
    <footer id="footer" class="footer">
      <div class="footer-top">
        <div class="container">
          <div class="inner">
            <div class="row">
              <div class="col-lg-3">
                <div class="footer-item footer-about">
                  <div class="logo">
                    <h4>Career Charge</h4>
                    <a href="index.html">
                      {/* <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="100"
                                            height="28"
                                            viewBox="0 0 100 28"
                                        >
                                            <g fill="none" fillRule="nonzero">
                                                <path
                                                fill="#111"
                                                d="M9.386 27.816c4.75 0 9.006-2.698 9.196-7.41.19-3.078-1.254-6.346-7.144-7.98l-3.23-.95c-2.812-.684-3.116-2.242-3.116-3.002 0-1.862 1.748-3.078 3.914-3.078 2.432 0 3.914 1.33 3.914 3.42h4.864c0-4.864-3.762-7.904-8.702-7.904C4.256.912.266 3.99.266 8.588c0 2.28.912 5.662 6.612 7.296l3.23.836c2.508.684 3.724 1.862 3.61 3.572-.114 1.786-1.71 3.154-4.332 3.154-2.812 0-4.408-1.748-4.522-3.686H0c0 4.484 3.8 8.056 9.386 8.056zm21.356-.076c1.71 0 4.408-.798 5.51-2.622v2.28h4.636V9.652h-4.636v2.204c-1.33-1.938-3.876-2.584-5.51-2.584-4.674 0-9.044 3.572-9.044 9.234s4.37 9.234 9.044 9.234zm.646-4.446c-2.584 0-4.902-1.9-4.902-4.788 0-2.888 2.318-4.788 4.902-4.788 2.432 0 4.864 1.748 4.864 4.788s-2.318 4.788-4.864 4.788zm19.38 4.104V0h-4.636v27.398h4.636zm13.186.342c1.71 0 4.408-.798 5.51-2.622v2.28H74.1V9.652h-4.636v2.204c-1.33-1.938-3.876-2.584-5.51-2.584-4.674 0-9.044 3.572-9.044 9.234s4.37 9.234 9.044 9.234zm.646-4.446c-2.584 0-4.902-1.9-4.902-4.788 0-2.888 2.318-4.788 4.902-4.788 2.432 0 4.864 1.748 4.864 4.788s-2.318 4.788-4.864 4.788z"
                                                transform="translate(-130 -26) translate(130 26)"
                                                ></path>
                                                <path
                                                fill="#0057FC"
                                                d="M1.88140704 16L13.319933 4.56530476 13.319933 15.1051574 16 15.0569324 16 0 0.940703518 0 0.940703518 2.63362358 11.438526 2.68184863 0 14.1165439z"
                                                transform="translate(-130 -26) translate(130 26) translate(84)"
                                                ></path>
                                            </g>
                                        </svg> */}
                      {/* CareerCharge */}
                    </a>
                  </div>
                  <p>Skills can take you to places where mass hiring can't. </p>
                  <p>
                    There are 25,000+ tech startups in India hunting for a
                    charged techie, exactly like YOU
                  </p>
                </div>
              </div>
              <div class="col-lg-2">
                <div class="footer-item footer-menu">
                  <h6>Company</h6>
                  <ul>
                    <li>
                      <a
                        href="/about-us"
                        title="About us"
                        style={{ textDecoration: "none" }}
                      >
                        About us
                      </a>
                    </li>
                    <li>
                      <a
                        href="/blogs"
                        title="Blog"
                        style={{ textDecoration: "none" }}
                      >
                        Blog
                      </a>
                    </li>
                    {/* <li><a href="careers.html" title="Careers">Careers</a></li> */}
                    <li>
                      <a
                        href="/contact-us"
                        title="Contact"
                        style={{ textDecoration: "none" }}
                      >
                        Contact
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-2">
                <div class="footer-item footer-menu">
                  <h6>Social Media</h6>
                  <ul>
                    <div className="socials" style={{ color: "#005aff" }}>
                      <li>
                        <FontAwesomeIcon
                          style={{ fontSize: 15 }}
                          icon={faLinkedin}
                        />
                        &nbsp;
                        <a
                          href="https://www.linkedin.com/company/careercharge/"
                          target="_blank"
                          title="Linkedin"
                          style={{ textDecoration: "none" }}
                        >
                          Linkedin
                        </a>
                      </li>
                      <li>
                        <FontAwesomeIcon
                          style={{ fontSize: 15 }}
                          icon={faInstagram}
                        />
                        &nbsp;
                        <a
                          href="https://instagram.com/careercharge.in"
                          target="_blank"
                          title="Instagram"
                          style={{ textDecoration: "none" }}
                        >
                          Instagram
                        </a>
                      </li>
                      {/* <li>
                        <FontAwesomeIcon
                          style={{ fontSize: 15 }}
                          icon={faFacebook}
                        />
                        &nbsp;
                        <a
                          href="https://www.facebook.com/profile.php?id=61550265838430"
                          target="_blank"
                          title="Facebook"
                          style={{ textDecoration: "none" }}
                        >
                        Facebook
                        </a>
                      </li> */}
                      <li>
                        <FontAwesomeIcon
                          style={{ fontSize: 15 }}
                          icon={faDiscord}
                        />
                        &nbsp;
                        <a
                          href="https://discord.gg/g5ZEsxMZ"
                          target="_blank"
                          title="Facebook"
                          style={{ textDecoration: "none" }}
                        >
                        Discord
                        </a>
                      </li>
                      <li>
                        <FontAwesomeIcon
                          style={{ fontSize: 15 }}
                          icon={faTwitter}
                        />
                        &nbsp;
                        <a
                          href="https://twitter.com/Career_Charge"
                          target="_blank"
                          title="Twitter"
                          style={{ textDecoration: "none" }}
                        >
                          Twitter
                        </a>
                      </li>
                    </div>
                  </ul>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="footer-item footer-newsletter">
                  <h6>Newsletter</h6>
                  <p>
                    Subcribe our newsletter and stay up to date about the
                    company
                  </p>
                  <div class="newsletter">
                    <div class="field-input">
                      <input
                        type="email"
                        onChange={(e) => handleNewsLetterEmailChange(e)}
                        value={newsLetterEmail}
                        placeholder="Email address"
                      />
                    </div>
                    <div class="field-submit">
                      <button onClick={handleNewsLetterSubmit}>Subcribe</button>
                    </div>
                  </div>
                  <div>{newsLetterMessage}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <div class="container">
          <div class="inner flex flex-content-sb flex-align-c">
            <div class="copyright">
              © 2023 Career Charge. All rights reserved
            </div>
            <div class="menu">
              <ul>
                <li>
                  <a href="/t&c" title="Terms">
                    Terms
                  </a>
                </li>
                <li>
                  <a href="/privacy-policy" title="Privacy Policy">
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
