import logo from './logo.svg';
import './App.css';
import HomeBanner from './components/homepage/HomeBanner';
import EnggStats from './components/homepage/EnggStats';
import RoadMap from './components/homepage/RoadMap';
import Features from './components/homepage/Features';
import Curriculum from './components/homepage/Curriculum';
import Footer from './components/footer/Footer';
import Header from './components/header/Header_salaui';
import HomePageView from './views/HomePageView';
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import AboutUsView from './views/aboutus/AboutUsView';
import ListBlogsView from './views/blog/ListBlogsView';
import NotFoundView from './views/404';
import ContactUs from './views/contactus/ContactUs';
import CoursesView from './views/courses/CoursesView';
import AdminListBlogsView from './views/admin/AdminListBlogsView';
import CreateBlogView from './views/admin/CreateBlogView';
import EditBlogView from './views/admin/EditBlogView';
import Blog from './views/blog/Blog';
import Testing from './views/testing';
import TermsAndCondtions from './views/t&c/TermsAndConditions';
import PrivacyPolicy from './views/privacypolicy/PrivacyPolicy';
import HireWithUs from './views/hirewithus/HireWithUs';
import CoursesComingSoonView from './views/courses/CoursesComingSoonView';
import SDEFullStackProgramView from './views/programs/SDEFullStackProgramView';
import MiniProgram from './components/programs/MiniProgram';
import ProgramCard from './components/programs/ProgramCard';
import RequestCallbackModal from './components/common/RequestCallbackModal';
import CreateJobView from './views/admin/job/CreateJobView';
import EditJobView from './views/admin/job/EditJobView';
import AdminListJobsView from './views/admin/job/AdminListJobs';
import JobsListingView from './views/job/JobsListing';
// import "bootstrap/dist/js/bootstrap.bundle.min";

function App() {
  return (
    <>
      <BrowserRouter basename='/'>
      <Routes>
          <Route path='/' element={<HomePageView />}></Route>
          <Route path='/about-us/' element={<AboutUsView />}></Route>
          <Route path='/blogs/' element={<ListBlogsView />}></Route>
          <Route path='program/software-development-full-stack-program' element={<SDEFullStackProgramView />}></Route>
          <Route path='programs' element={<CoursesView />}></Route>
          <Route path='contact-us' element={<ContactUs />}></Route>
          <Route path='admin/blogs' element={<AdminListBlogsView />}></Route>
          <Route path='admin/blogs/create' element={<CreateBlogView />}></Route>
          <Route path='admin/blogs/:blogId/edit' element={<EditBlogView />}></Route>
          <Route path='admin/jobs' element={<AdminListJobsView />}></Route>
          <Route path='admin/jobs/create' element={<CreateJobView />}></Route>
          <Route path='admin/jobs/:jobId/edit' element={<EditJobView />}></Route>
          <Route path='blogs/:blogId' element={<Blog />}></Route>
          <Route path='jobs' element={<JobsListingView />}></Route>
          <Route path='hire-with-us' element={<HireWithUs />}></Route>
          <Route path='t&c' element={<TermsAndCondtions />}></Route>
          <Route path='privacy-policy' element={<PrivacyPolicy />}></Route>
          <Route path='testing' element={<Testing />}></Route>
          <Route path='*' element={<NotFoundView />}></Route>
          <Route path='/test' element={<ProgramCard />}></Route>
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
