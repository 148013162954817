import { Container, Row, Col, Button, Form, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import RestClient from "../../services/RestClient";
const CurriculumDownloadModal = (props) => {
  const [apiMessage, setApiMessage] = useState("");
  const [mandatoryFieldsError, setMandatoryFieldsError] = useState("");
  const [restCallLoading, setRestCallLoading] = useState(false);
  const [downloadPdfRequest, setDownloadPdfRequest] = useState({});
  const [pdfLink, setPdfLink] = useState("");

  const mandatoryFields = { email: "string" };

  const resetState = () => {
    setApiMessage("");
    setMandatoryFieldsError("");
    setRestCallLoading(false);
    setDownloadPdfRequest({});
  };

  const handleDownloadPdfFormChange = (event, field, fieldType) => {
    console.log(field);
    switch (fieldType) {
      case "checkbox":
        let values = downloadPdfRequest[field] || [];
        if (event.target.checked) {
          values.push(event.target.value);
        } else {
          values = values.filter((val) => val != event.target.value);
        }
        setDownloadPdfRequest({ ...downloadPdfRequest, [field]: values });
        break;
      default:
        setDownloadPdfRequest({
          ...downloadPdfRequest,
          [field]: event.target.value,
        });
        break;
    }
  };

  const constructDownloadPdfRequestPayload = () => {
    const payload = {};
    payload.email = downloadPdfRequest.email;
    return payload;
  };

  const validateDownloadPdfRequestPayload = (payload) => {
    const errors = [];
    if (!payload) {
      return errors;
    }
    for (let field of Object.keys(mandatoryFields)) {
      switch (mandatoryFields[field]) {
        case "string":
          if (!payload[field]) {
            errors.push(field);
          }
          break;
        case "array":
          if (!payload[field] || !payload[field].length) {
            errors.push(field);
          }
          break;
        default:
          if (!payload[field]) {
            errors.push(field);
          }
          break;
      }
    }

    return errors;
  };

  const handleSubmit = (event) => {
    const payload = constructDownloadPdfRequestPayload();
    console.log(payload);
    const errors = validateDownloadPdfRequestPayload(payload);
    console.log(errors);
    event.preventDefault();

    if (!!pdfLink) {
      setApiMessage("Already pdfLink exists. Please check");
      setTimeout(() => {
        setApiMessage(null);
      }, 5000);
      return;
    }

    if (!!errors && !!errors.length) {
      setMandatoryFieldsError(errors.join(", ") + " missing");
      return;
    } else {
      setMandatoryFieldsError("");
    }
    // event.target.reset();
    // setApiMessage("Submitting....");
    setRestCallLoading(true);
    RestClient.doPost("curriculum/download", payload)
      .then((response) => {
        if (response.status === 200) {
          if (!!response.data && !!response.data.pdfLink) {
            setPdfLink(response.data.pdfLink);
            window.open(response.data.pdfLink, "_blank");
            props.handleClose();
          } else {
            setApiMessage("Something went wrong. Please try again");
          }
          setDownloadPdfRequest({});
          event.target.reset();
        }
      })
      .catch((err) => {
        if (
          !!err &&
          !!err.response &&
          !!err.response.data &&
          !!err.response.data.errorMessage
        ) {
          setApiMessage(err.response.data.errorMessage);
        } else {
          setApiMessage("Something went wrong. Please try again");
        }
      })
      .finally(() => {
        setRestCallLoading(false);
      });
    setTimeout(() => {
      setApiMessage(null);
    }, 5000);
  };

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      style={{ marginTop: "100px" }}
    >
      <Modal.Header
        closeButton
        style={{
          padding: "10px 30px 0px",
          paddingBottom: "0px",
          border: "0px",
        }}
        onHide={resetState}
      >
        {/* <Modal.Title>Our Expert will reach you</Modal.Title> */}
        {/* <Modal.Title>Talk to our Expert</Modal.Title> */}
        <div>{/* <h3>Download Curriculum</h3> */}</div>
      </Modal.Header>
      <Modal.Body style={{ paddingBottom: "5px" }}>
        <Form
          className="pr-10"
          onSubmit={handleSubmit}
          style={{
            border: "0px solid gray",
            padding: "10px",
            borderRadius: "5px",
            marginTop: "-20px",
          }}
          autoComplete="off"
        >
          <br />
          <Form.Group className="mb-3" controlId="formBasicPassword">
            {/* <Form.Label style={{fontWeight: 'bold'}}>Email</Form.Label> */}
            <Form.Control
              className="form-control"
              style={{
                height: "40px",
                padding: "0px 10px",
                outline: "none",
                boxShadow: "none",
              }}
              type="text"
              placeholder="Enter your email"
              value={downloadPdfRequest.email}
              onChange={(e) => handleDownloadPdfFormChange(e, "email")}
            />
          </Form.Group>
          {!!pdfLink && (
            <div>
              <span style={{ fontSize: "14px" }}>
                Download your curriculum here:{" "}
                <a href={pdfLink} target="_blank">
                  Link
                </a>
              </span>
            </div>
          )}
          {mandatoryFieldsError && (
            <div>
              <span style={{ fontSize: "14px", color: "red" }}>
                *{mandatoryFieldsError}
              </span>
            </div>
          )}
          {apiMessage && (
            <div>
              <span style={{ fontSize: "14px", color: "orange" }}>
                {apiMessage}
              </span>
            </div>
          )}
          <div style={{ textAlign: "center", marginTop: "10px" }}>
            <Button
              variant="primary"
              size="lg"
              type="submit"
              disabled={restCallLoading || !!pdfLink}
            >
              Download
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CurriculumDownloadModal;
