import Modal from "react-bootstrap/Modal";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import RestClient from "../../services/RestClient";
const MasterClassRegistrationModal = (props) => {
  const [apiMessage, setApiMessage] = useState("");
  const [mandatoryFieldsError, setMandatoryFieldsError] = useState("");
  const [restCallLoading, setRestCallLoading] = useState(false);
  const [masterClassRegistrationRequest, setMasterClassRegistrationRequest] =
    useState({ masterClassType: props.masterClassType });

  const mandatoryFields = {
    name: "string",
    study: "string",
    graduationYear: "string",
    college: "string",
    phoneNumber: "string",
  };

  const resetState = () => {
    setApiMessage("");
    setMandatoryFieldsError("");
    setRestCallLoading(false);
    setMasterClassRegistrationRequest({});
  };

  const handleMasterClassRegistrationFormChange = (event, field, fieldType) => {
    console.log(field);
    switch (fieldType) {
      case "checkbox":
        let values = masterClassRegistrationRequest[field] || [];
        if (event.target.checked) {
          values.push(event.target.value);
        } else {
          values = values.filter((val) => val != event.target.value);
        }
        setMasterClassRegistrationRequest({
          ...masterClassRegistrationRequest,
          [field]: values,
        });
        break;
      default:
        setMasterClassRegistrationRequest({
          ...masterClassRegistrationRequest,
          [field]: event.target.value,
        });
        break;
    }
  };

  const constructMasterClassRegistrationRequestPayload = () => {
    const payload = {};
    payload["name"] = masterClassRegistrationRequest.name;
    payload["study"] = masterClassRegistrationRequest.study;
    if (payload["study"] == "other") {
      payload["study"] = masterClassRegistrationRequest.studyOther;
    }
    payload["graduationYear"] = masterClassRegistrationRequest.graduationYear;
    if (payload["graduationYear"] == "other") {
      payload["graduationYear"] =
        masterClassRegistrationRequest.graduationYearOther;
    }
    payload["college"] = masterClassRegistrationRequest.college;
    payload["phoneNumber"] = masterClassRegistrationRequest.phoneNumber;

    return payload;
  };

  const validateMasterClassRegistrationRequestPayload = (payload) => {
    const errors = [];
    if (!payload) {
      return errors;
    }
    for (let field of Object.keys(mandatoryFields)) {
      switch (mandatoryFields[field]) {
        case "string":
          if (!payload[field]) {
            errors.push(field);
          }
          break;
        case "array":
          if (!payload[field] || !payload[field].length) {
            errors.push(field);
          }
          break;
        default:
          if (!payload[field]) {
            errors.push(field);
          }
          break;
      }
    }

    return errors;
  };

  const handleSubmit = (event) => {
    console.log(masterClassRegistrationRequest);
    const payload = constructMasterClassRegistrationRequestPayload();
    console.log(payload);
    const errors = validateMasterClassRegistrationRequestPayload(payload);
    console.log(errors);
    event.preventDefault();
    if (!!errors && !!errors.length) {
      setMandatoryFieldsError(errors.join(", ") + " are missing");
      return;
    } else {
      setMandatoryFieldsError("");
    }
    // event.target.reset();
    // setApiMessage("Submitting....");
    setRestCallLoading(true);
    payload["masterClassType"] = props.masterClassType; // mandatory field to know from where it is being called
    RestClient.doPost("masterClass/register", payload)
      .then((response) => {
        if (response.status === 200) {
          console.log(response);
          setMasterClassRegistrationRequest({});
          setApiMessage("Great!! We will send the details to your contact!!");
          event.target.reset();
        }
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
        setApiMessage("Something went wrong. Please try again");
      })
      .finally(() => {
        console.log("hi");
        setRestCallLoading(false);
      });
    setTimeout(() => {
      setApiMessage(null);
    }, 5000);
  };

  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header
        closeButton
        style={{ padding: "10px 30px 0px", paddingBottom: "0px" }}
        onHide={resetState}
      >
        {/* <Modal.Title>Our Expert will reach you</Modal.Title> */}
        {/* <Modal.Title>Talk to our Expert</Modal.Title> */}
        <div>
          <h3>Master Class Registration</h3>
        </div>
      </Modal.Header>
      <Modal.Body style={{ paddingBottom: "5px" }}>
        <Form
          className="pr-10"
          onSubmit={handleSubmit}
          style={{
            border: "0px solid gray",
            padding: "10px",
            borderRadius: "5px",
            marginTop: "-20px",
          }}
          autoComplete="off"
        >
          <br />
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label style={{ fontWeight: "bold" }}>Name</Form.Label>
            <Form.Control
              style={{
                height: "30px",
                padding: "0px 10px",
                outline: "none",
                boxShadow: "none",
              }}
              type="text"
              placeholder="Enter your name...."
              value={masterClassRegistrationRequest.name}
              onChange={(e) =>
                handleMasterClassRegistrationFormChange(e, "name")
              }
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label style={{ fontWeight: "bold" }}>
              Graduation Year
            </Form.Label>
            <div className="mb-3" style={{ display: "flex", gap: "20px" }}>
              <div>
                {" "}
                <input
                  type="radio"
                  checked={
                    masterClassRegistrationRequest.graduationYear == "2024"
                  }
                  name="graduationYear"
                  value="2024"
                  style={{ width: "auto", height: "auto" }}
                  onChange={(e) =>
                    handleMasterClassRegistrationFormChange(e, "graduationYear")
                  }
                />{" "}
                2024{" "}
              </div>
              <div>
                {" "}
                <input
                  type="radio"
                  checked={
                    masterClassRegistrationRequest.graduationYear == "2025"
                  }
                  name="graduationYear"
                  value="2025"
                  style={{ width: "auto", height: "auto" }}
                  onChange={(e) =>
                    handleMasterClassRegistrationFormChange(e, "graduationYear")
                  }
                />{" "}
                2025{" "}
              </div>
              <div>
                {" "}
                <input
                  type="radio"
                  checked={
                    masterClassRegistrationRequest.graduationYear == "other"
                  }
                  name="graduationYear"
                  value="other"
                  style={{ width: "auto", height: "auto" }}
                  onChange={(e) =>
                    handleMasterClassRegistrationFormChange(e, "graduationYear")
                  }
                />{" "}
                Other{" "}
              </div>
              {!!masterClassRegistrationRequest &&
                !!masterClassRegistrationRequest.graduationYear &&
                masterClassRegistrationRequest.graduationYear == "other" && (
                  <div>
                    <Form.Control
                      style={{
                        height: "30px",
                        marginTop: "-4px",
                        padding: "0px 10px",
                        outline: "none",
                        boxShadow: "none",
                      }}
                      type="text"
                      placeholder="Eg: 2021"
                      value={masterClassRegistrationRequest.graduationYearOther}
                      onChange={(e) =>
                        handleMasterClassRegistrationFormChange(
                          e,
                          "graduationYearOther",
                        )
                      }
                    />
                  </div>
                )}
            </div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label style={{ fontWeight: "bold" }}>
              I am pursuing
            </Form.Label>
            <div className="mb-3" style={{ display: "flex", gap: "20px" }}>
              <div>
                {" "}
                <input
                  type="radio"
                  checked={masterClassRegistrationRequest.study == "CSE"}
                  name="study"
                  value="CSE"
                  style={{ width: "auto", height: "auto" }}
                  onChange={(e) =>
                    handleMasterClassRegistrationFormChange(e, "study")
                  }
                />{" "}
                CSE{" "}
              </div>
              <div>
                {" "}
                <input
                  type="radio"
                  checked={masterClassRegistrationRequest.study == "ECE"}
                  name="study"
                  value="ECE"
                  style={{ width: "auto", height: "auto" }}
                  onChange={(e) =>
                    handleMasterClassRegistrationFormChange(e, "study")
                  }
                />{" "}
                ECE{" "}
              </div>
              <div>
                {" "}
                <input
                  type="radio"
                  checked={masterClassRegistrationRequest.study == "CIVIL"}
                  name="study"
                  value="CIVIL"
                  style={{ width: "auto", height: "auto" }}
                  onChange={(e) =>
                    handleMasterClassRegistrationFormChange(e, "study")
                  }
                />{" "}
                CIVIL{" "}
              </div>
              <div>
                {" "}
                <input
                  type="radio"
                  checked={masterClassRegistrationRequest.study == "MECH"}
                  name="study"
                  value="MECH"
                  style={{ width: "auto", height: "auto" }}
                  onChange={(e) =>
                    handleMasterClassRegistrationFormChange(e, "study")
                  }
                />{" "}
                MECH{" "}
              </div>
              <div>
                {" "}
                <input
                  type="radio"
                  checked={masterClassRegistrationRequest.study == "other"}
                  name="study"
                  value="other"
                  style={{ width: "auto", height: "auto" }}
                  onChange={(e) =>
                    handleMasterClassRegistrationFormChange(e, "study")
                  }
                />{" "}
                Other{" "}
              </div>
              {!!masterClassRegistrationRequest &&
                !!masterClassRegistrationRequest.study &&
                masterClassRegistrationRequest.study == "other" && (
                  <div>
                    <Form.Control
                      style={{
                        height: "30px",
                        marginTop: "-4px",
                        padding: "0px 10px",
                        outline: "none",
                        boxShadow: "none",
                      }}
                      type="text"
                      placeholder="Eg: BSC"
                      value={masterClassRegistrationRequest.studyOther}
                      onChange={(e) =>
                        handleMasterClassRegistrationFormChange(e, "studyOther")
                      }
                    />
                  </div>
                )}
            </div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label style={{ fontWeight: "bold" }}>
              College / University
            </Form.Label>
            <Form.Control
              style={{
                height: "30px",
                padding: "0px 10px",
                outline: "none",
                boxShadow: "none",
              }}
              type="text"
              placeholder="Eg: KITS"
              value={masterClassRegistrationRequest.college}
              onChange={(e) =>
                handleMasterClassRegistrationFormChange(e, "college")
              }
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label style={{ fontWeight: "bold" }}>
              Mobile Number
            </Form.Label>
            <Form.Control
              style={{
                height: "30px",
                padding: "0px 10px",
                outline: "none",
                boxShadow: "none",
              }}
              type="number"
              placeholder="Eg: 9878022335"
              value={masterClassRegistrationRequest.phoneNumber}
              onChange={(e) =>
                handleMasterClassRegistrationFormChange(e, "phoneNumber")
              }
            />
          </Form.Group>
          {mandatoryFieldsError && (
            <div>
              <span style={{ fontSize: "14px", color: "red" }}>
                *{mandatoryFieldsError}
              </span>
            </div>
          )}
          {apiMessage && (
            <div style={{ marginLeft: "20px" }}>
              <span style={{ fontSize: "14px", color: "orange" }}>
                {apiMessage}
              </span>
            </div>
          )}
          <div style={{ textAlign: "center", marginTop: "10px" }}>
            <Button
              variant="primary"
              size="sm"
              type="submit"
              disabled={restCallLoading}
            >
              Submit
            </Button>
          </div>
        </Form>
      </Modal.Body>
      {/* <Modal.Footer>
            <Button variant="secondary" onClick={props.handleClose}>
                Close
            </Button>
            <Button variant="primary" onClick={props.handleClose}>
                Save Changes
            </Button>
            </Modal.Footer> */}
    </Modal>
  );
};

export default MasterClassRegistrationModal;
