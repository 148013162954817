import { Container, Row, Col, Button, Form, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import RestClient from "../../services/RestClient";
const TalkToExpertRegistraionModal = (props) => {
  const [apiMessage, setApiMessage] = useState("");
  const [mandatoryFieldsError, setMandatoryFieldsError] = useState("");
  const [restCallLoading, setRestCallLoading] = useState(false);
  const [counsellingRequest, setCounsellingRequest] = useState({});

  const mandatoryFields = {
    name: "string",
    study: "string",
    graduationYear: "string",
    phoneNumber: "string",
  };

  const resetState = () => {
    setApiMessage("");
    setMandatoryFieldsError("");
    setRestCallLoading(false);
    setCounsellingRequest({});
  };

  const handleCounsellingFormChange = (event, field, fieldType) => {
    console.log(field);
    switch (fieldType) {
      case "checkbox":
        let values = counsellingRequest[field] || [];
        if (event.target.checked) {
          values.push(event.target.value);
        } else {
          values = values.filter((val) => val != event.target.value);
        }
        setCounsellingRequest({ ...counsellingRequest, [field]: values });
        break;
      default:
        setCounsellingRequest({
          ...counsellingRequest,
          [field]: event.target.value,
        });
        break;
    }
  };

  const constructCounsellingRequestPayload = () => {
    const payload = {};
    payload["name"] = counsellingRequest.name;
    payload["study"] = counsellingRequest.study;
    if (payload["study"] == "other") {
      payload["study"] = counsellingRequest.studyOther;
    }
    payload["graduationYear"] = counsellingRequest.graduationYear;
    if (payload["graduationYear"] == "other") {
      payload["graduationYear"] = counsellingRequest.graduationYearOther;
    }
    // payload["goodAt"] = counsellingRequest.goodAt || [];
    // if (!!counsellingRequest.goodAtOther) {
    //   payload["goodAt"].push(counsellingRequest.goodAtOther);
    // }
    // payload["codingSkillsRating"] = counsellingRequest.codingSkillsRating;
    payload["phoneNumber"] = counsellingRequest.phoneNumber;
    payload["question"] = counsellingRequest.question;

    return payload;
  };

  const validateCounsellingRequestPayload = (payload) => {
    const errors = [];
    if (!payload) {
      return errors;
    }
    for (let field of Object.keys(mandatoryFields)) {
      switch (mandatoryFields[field]) {
        case "string":
          if (!payload[field]) {
            errors.push(field);
          }
          break;
        case "array":
          if (!payload[field] || !payload[field].length) {
            errors.push(field);
          }
          break;
        default:
          if (!payload[field]) {
            errors.push(field);
          }
          break;
      }
    }

    return errors;
  };

  const handleSubmit = (event) => {
    console.log(counsellingRequest);
    const payload = constructCounsellingRequestPayload();
    console.log(payload);
    const errors = validateCounsellingRequestPayload(payload);
    console.log(errors);
    event.preventDefault();
    if (!!errors && !!errors.length) {
      setMandatoryFieldsError(errors.join(", ") + " are missing");
      return;
    } else {
      setMandatoryFieldsError("");
    }
    // event.target.reset();
    // setApiMessage("Submitting....");
    setRestCallLoading(true);
    RestClient.doPost("careerCounsellingRequests", payload)
      .then((response) => {
        if (response.status === 200) {
          console.log(response);
          setCounsellingRequest({});
          setApiMessage("Great! Our expert will contact you soon");
          event.target.reset();
        }
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
        setApiMessage("Something went wrong. Please try again");
      })
      .finally(() => {
        console.log("hi");
        setRestCallLoading(false);
      });
    setTimeout(() => {
      setApiMessage(null);
    }, 5000);
  };

  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header
        closeButton
        style={{ padding: "10px 30px 0px", paddingBottom: "0px" }}
        onHide={resetState}
      >
        {/* <Modal.Title>Our Expert will reach you</Modal.Title> */}
        {/* <Modal.Title>Talk to our Expert</Modal.Title> */}
        <div>
          <h3>Talk to our Expert</h3>
        </div>
      </Modal.Header>
      <Modal.Body style={{ paddingBottom: "5px" }}>
        <Form
          className="pr-10"
          onSubmit={handleSubmit}
          style={{
            border: "0px solid gray",
            padding: "10px",
            borderRadius: "5px",
            marginTop: "-20px",
          }}
          autoComplete="off"
        >
          <br />
          <Form.Group className="mb-3" controlId="name">
            <Form.Label style={{ fontWeight: "bold" }}>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              placeholder="Enter your name...."
              value={counsellingRequest.name}
              onChange={(e) => handleCounsellingFormChange(e, "name")}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label style={{ fontWeight: "bold" }}>
              I am pursuing
            </Form.Label>
            <div className="mb-3" style={{ display: "flex", gap: "20px" , flexWrap: 'wrap'}}>
              <div>
                {" "}
                <input
                  type="radio"
                  checked={counsellingRequest.study == "CSE"}
                  name="study"
                  value="CSE"
                  style={{ width: "auto", height: "auto" }}
                  id="cse"
                  onChange={(e) => handleCounsellingFormChange(e, "study")}
                />{" "}
                <label for="cse">CSE</label>
              </div>
              <div>
                {" "}
                <input
                  type="radio"
                  checked={counsellingRequest.study == "ECE"}
                  name="study"
                  value="ECE"
                  style={{ width: "auto", height: "auto" }}
                  id="ece"
                  onChange={(e) => handleCounsellingFormChange(e, "study")}
                />{" "}
                <label for="ece">ECE</label>
              </div>
              <div>
                {" "}
                <input
                  type="radio"
                  checked={counsellingRequest.study == "CIVIL"}
                  name="study"
                  value="CIVIL"
                  style={{ width: "auto", height: "auto" }}
                  id="civil"
                  onChange={(e) => handleCounsellingFormChange(e, "study")}
                />{" "}
                <label for="civil">CIVIL</label>
              </div>
              <div>
                {" "}
                <input
                  type="radio"
                  checked={counsellingRequest.study == "MECH"}
                  name="study"
                  value="MECH"
                  style={{ width: "auto", height: "auto" }}
                  id="mech"
                  onChange={(e) => handleCounsellingFormChange(e, "study")}
                />{" "}
                <label for="mech">MECH</label>
              </div>
              <div>
                {" "}
                <input
                  type="radio"
                  checked={counsellingRequest.study == "other"}
                  name="study"
                  value="other"
                  style={{ width: "auto", height: "auto" }}
                  id="other_study"
                  onChange={(e) => handleCounsellingFormChange(e, "study")}
                />{" "}
                <label for="other_study">Other</label>
              </div>
              {!!counsellingRequest &&
                !!counsellingRequest.study &&
                counsellingRequest.study == "other" && (
                  <div>
                    <Form.Control
                      style={{
                        height: "30px",
                        marginTop: "-4px",
                        padding: "0px 10px",
                        outline: "none",
                        boxShadow: "none",
                      }}
                      type="text"
                      placeholder="Eg: BSC"
                      value={counsellingRequest.studyOther}
                      onChange={(e) =>
                        handleCounsellingFormChange(e, "studyOther")
                      }
                    />
                  </div>
                )}
            </div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label style={{ fontWeight: "bold" }}>
              Year of Graduation
            </Form.Label>
            <div className="mb-3" style={{ display: "flex", gap: "20px" }}>
              <div>
                {" "}
                <input
                  type="radio"
                  checked={counsellingRequest.graduationYear == "2024"}
                  name="graduationYear"
                  value="2024"
                  style={{ width: "auto", height: "auto" }}
                  id="2024"
                  onChange={(e) =>
                    handleCounsellingFormChange(e, "graduationYear")
                  }
                />{" "}
                <label for="2024">2024</label>
              </div>
              <div>
                {" "}
                <input
                  type="radio"
                  checked={counsellingRequest.graduationYear == "2025"}
                  name="graduationYear"
                  value="2025"
                  style={{ width: "auto", height: "auto" }}
                  id="2025"
                  onChange={(e) =>
                    handleCounsellingFormChange(e, "graduationYear")
                  }
                />{" "}
                <label for="2025">2025</label>
              </div>
              <div>
                {" "}
                <input
                  type="radio"
                  checked={counsellingRequest.graduationYear == "other"}
                  name="graduationYear"
                  value="other"
                  style={{ width: "auto", height: "auto" }}
                  id="other_gy"
                  onChange={(e) =>
                    handleCounsellingFormChange(e, "graduationYear")
                  }
                />{" "}
                <label for="other_gy">Other</label>
              </div>
              {!!counsellingRequest &&
                !!counsellingRequest.graduationYear &&
                counsellingRequest.graduationYear == "other" && (
                  <div>
                    <Form.Control
                      style={{
                        height: "30px",
                        marginTop: "-4px",
                        padding: "0px 10px",
                        outline: "none",
                        boxShadow: "none",
                      }}
                      type="text"
                      placeholder="Eg: 2021"
                      value={counsellingRequest.graduationYearOther}
                      onChange={(e) =>
                        handleCounsellingFormChange(e, "graduationYearOther")
                      }
                    />
                  </div>
                )}
            </div>
          </Form.Group>
          {/* <Form.Group className="mb-3" controlId="formBasicEduation">
            <Form.Label style={{ fontWeight: "bold" }}>I am good at</Form.Label>
            <div
              className="mb-3"
              style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}
            >
              <div>
                {" "}
                <input
                  type="checkbox"
                  name="goodAt"
                  checked={
                    !!counsellingRequest.goodAt &&
                    counsellingRequest.goodAt.indexOf("C") != -1
                  }
                  value="C"
                  style={{ width: "auto", height: "auto" }}
                  onChange={(e) =>
                    handleCounsellingFormChange(e, "goodAt", "checkbox")
                  }
                />{" "}
                C{" "}
              </div>
              <div>
                {" "}
                <input
                  type="checkbox"
                  name="goodAt"
                  checked={
                    !!counsellingRequest.goodAt &&
                    counsellingRequest.goodAt.indexOf("JAVA") != -1
                  }
                  value="JAVA"
                  style={{ width: "auto", height: "auto" }}
                  onChange={(e) =>
                    handleCounsellingFormChange(e, "goodAt", "checkbox")
                  }
                />{" "}
                JAVA{" "}
              </div>
              <div>
                {" "}
                <input
                  type="checkbox"
                  name="goodAt"
                  checked={
                    !!counsellingRequest.goodAt &&
                    counsellingRequest.goodAt.indexOf("PYTHON") != -1
                  }
                  value="PYTHON"
                  style={{ width: "auto", height: "auto" }}
                  onChange={(e) =>
                    handleCounsellingFormChange(e, "goodAt", "checkbox")
                  }
                />{" "}
                Python{" "}
              </div>
              <div>
                {" "}
                <input
                  type="checkbox"
                  name="goodAt"
                  checked={
                    !!counsellingRequest.goodAt &&
                    counsellingRequest.goodAt.indexOf("HTML, CSS, JS") != -1
                  }
                  value="HTML, CSS, JS"
                  style={{ width: "auto", height: "auto" }}
                  onChange={(e) =>
                    handleCounsellingFormChange(e, "goodAt", "checkbox")
                  }
                />{" "}
                HTML, CSS, JS{" "}
              </div>
              <div>
                {" "}
                <input
                  type="checkbox"
                  name="goodAt"
                  checked={
                    !!counsellingRequest.goodAt &&
                    counsellingRequest.goodAt.indexOf("ReactJS") != -1
                  }
                  value="ReactJS"
                  style={{ width: "auto", height: "auto" }}
                  onChange={(e) =>
                    handleCounsellingFormChange(e, "goodAt", "checkbox")
                  }
                />{" "}
                React JS{" "}
              </div>
              <div>
                {" "}
                <input
                  type="checkbox"
                  name="goodAt"
                  checked={
                    !!counsellingRequest.goodAt &&
                    counsellingRequest.goodAt.indexOf("NodeJS") != -1
                  }
                  value="NodeJS"
                  style={{ width: "auto", height: "auto" }}
                  onChange={(e) =>
                    handleCounsellingFormChange(e, "goodAt", "checkbox")
                  }
                />{" "}
                NodeJS{" "}
              </div>
              <div>
                <Form.Control
                  style={{
                    height: "30px",
                    marginTop: "-4px",
                    padding: "0px 10px",
                    outline: "none",
                    boxShadow: "none",
                  }}
                  type="text"
                  placeholder="Any other enter here..."
                  value={counsellingRequest.goodAtOther}
                  onChange={(e) =>
                    handleCounsellingFormChange(e, "goodAtOther")
                  }
                />
              </div>
            </div>
          </Form.Group> */}
          {/* <Form.Group className="mb-3" controlId="formBasicCollegeName">
            <Form.Label style={{ fontWeight: "bold" }}>
              I can rate my coding skills as
            </Form.Label>
            <div
              className="mb-3"
              style={{ display: "flex", columnGap: "50px", flexWrap: "wrap" }}
            >
              <div>
                {" "}
                <input
                  type="radio"
                  value="1"
                  checked={counsellingRequest.codingSkillsRating == "1"}
                  name="codingSkillsRating"
                  style={{ width: "auto", height: "auto" }}
                  onChange={(e) =>
                    handleCounsellingFormChange(e, "codingSkillsRating")
                  }
                />{" "}
                1{" "}
              </div>
              <div>
                {" "}
                <input
                  type="radio"
                  value="2"
                  checked={counsellingRequest.codingSkillsRating == "2"}
                  name="codingSkillsRating"
                  style={{ width: "auto", height: "auto" }}
                  onChange={(e) =>
                    handleCounsellingFormChange(e, "codingSkillsRating")
                  }
                />{" "}
                2{" "}
              </div>
              <div>
                {" "}
                <input
                  type="radio"
                  value="3"
                  checked={counsellingRequest.codingSkillsRating == "3"}
                  name="codingSkillsRating"
                  style={{ width: "auto", height: "auto" }}
                  onChange={(e) =>
                    handleCounsellingFormChange(e, "codingSkillsRating")
                  }
                />{" "}
                3{" "}
              </div>
              <div>
                {" "}
                <input
                  type="radio"
                  value="4"
                  checked={counsellingRequest.codingSkillsRating == "4"}
                  name="codingSkillsRating"
                  style={{ width: "auto", height: "auto" }}
                  onChange={(e) =>
                    handleCounsellingFormChange(e, "codingSkillsRating")
                  }
                />{" "}
                4{" "}
              </div>
              <div>
                {" "}
                <input
                  type="radio"
                  value="5"
                  checked={counsellingRequest.codingSkillsRating == "5"}
                  name="codingSkillsRating"
                  style={{ width: "auto", height: "auto" }}
                  onChange={(e) =>
                    handleCounsellingFormChange(e, "codingSkillsRating")
                  }
                />{" "}
                5{" "}
              </div>
              <div>
                {" "}
                <input
                  type="radio"
                  value="6"
                  checked={counsellingRequest.codingSkillsRating == "6"}
                  name="codingSkillsRating"
                  style={{ width: "auto", height: "auto" }}
                  onChange={(e) =>
                    handleCounsellingFormChange(e, "codingSkillsRating")
                  }
                />{" "}
                6{" "}
              </div>
              <div>
                {" "}
                <input
                  type="radio"
                  value="7"
                  checked={counsellingRequest.codingSkillsRating == "7"}
                  name="codingSkillsRating"
                  style={{ width: "auto", height: "auto" }}
                  onChange={(e) =>
                    handleCounsellingFormChange(e, "codingSkillsRating")
                  }
                />{" "}
                7{" "}
              </div>
              <div>
                {" "}
                <input
                  type="radio"
                  value="8"
                  checked={counsellingRequest.codingSkillsRating == "8"}
                  name="codingSkillsRating"
                  style={{ width: "auto", height: "auto" }}
                  onChange={(e) =>
                    handleCounsellingFormChange(e, "codingSkillsRating")
                  }
                />{" "}
                8{" "}
              </div>
              <div>
                {" "}
                <input
                  type="radio"
                  value="9"
                  checked={counsellingRequest.codingSkillsRating == "9"}
                  name="codingSkillsRating"
                  style={{ width: "auto", height: "auto" }}
                  onChange={(e) =>
                    handleCounsellingFormChange(e, "codingSkillsRating")
                  }
                />{" "}
                9{" "}
              </div>
              <div>
                {" "}
                <input
                  type="radio"
                  value="10"
                  checked={counsellingRequest.codingSkillsRating == "10"}
                  name="codingSkillsRating"
                  style={{ width: "auto", height: "auto" }}
                  onChange={(e) =>
                    handleCounsellingFormChange(e, "codingSkillsRating")
                  }
                />{" "}
                10{" "}
              </div>
            </div>
          </Form.Group> */}
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label style={{ fontWeight: "bold" }}>
              Mobile Number
            </Form.Label>
            <Form.Control
              type="tel"
              placeholder="Eg: 9878022335"
              value={counsellingRequest.phoneNumber}
              onChange={(e) => handleCounsellingFormChange(e, "phoneNumber")}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label style={{ fontWeight: "bold" }}>
              I've a question
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Eg: Why careercharge?"
              value={counsellingRequest.question}
              onChange={(e) => handleCounsellingFormChange(e, "question")}
            />
          </Form.Group>

          {mandatoryFieldsError && (
            <div>
              <span style={{ fontSize: "14px", color: "red" }}>
                *{mandatoryFieldsError}
              </span>
            </div>
          )}
          {apiMessage && (
            <div style={{ marginLeft: "20px" }}>
              <span style={{ fontSize: "14px", color: "blue" }}>
                {apiMessage}
              </span>
            </div>
          )}
          <div style={{ textAlign: "center", marginTop: "10px" }}>
            <Button
              variant="primary"
              size="sm"
              type="submit"
              disabled={restCallLoading}
            >
              Submit
            </Button>
          </div>
        </Form>
      </Modal.Body>
      {/* <Modal.Footer>
            <Button variant="secondary" onClick={props.handleClose}>
                Close
            </Button>
            <Button variant="primary" onClick={props.handleClose}>
                Save Changes
            </Button>
            </Modal.Footer> */}
    </Modal>
  );
};

export default TalkToExpertRegistraionModal;
