import { useParams } from "react-router-dom";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import RestClient from "../../services/RestClient";
import TalkToExpertRegistraionModal from "../../components/common/TalkToExpertRegistrationModal";
import "./Blog.css";
import { getDateFromTimeMillis } from "../../utils/TimeUtils";

function Blog() {
  const { blogId } = useParams();
  const [blog, setBlog] = useState({});
  const [showExpertModal, setShowExpertModal] = useState(false);
  const handleShowExpertModalClose = () => {
    setShowExpertModal(false);
  };

  useEffect(() => {
    RestClient.doGet(`blogs/${blogId}`)
      .then((response) => {
        console.log(response);
        setBlog(response.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Header />
      <div style={{ marginTop: "80px" }}>
        <div className="blogContainer">
          {!!blog.title ? (
            <>
              <h1 className="blogTitle">{blog.title}</h1>
              <div className="authorDateDiv">
                <span>{blog.author}</span>
                <span>{getDateFromTimeMillis(blog.creationTime)}</span>
              </div>
              <div className="blogContent">
                <div dangerouslySetInnerHTML={{ __html: blog.content }} />
              </div>
              <div>
                Click below to talk to an expert from Career Charge to know more
                interesting hacks to be a good developer and develop a
                personalized plan to land your dream job.
              </div>
              <section>
                <br />
                <div
                  class="container background-navy"
                  style={{ paddingTop: "3%", paddingBottom: "3%" }}
                >
                  <div class="heading heading-alway-white align-center mb32">
                    <h2 class="heading-title size-m">
                      When you are in doubt, you'all always regret later
                    </h2>
                  </div>
                  <div class="button-wrap align-center">
                    <span
                      class="button fullfield-white"
                      title="Try it free"
                      onClick={() => setShowExpertModal(true)}
                    >
                      Talk To Expert
                    </span>
                  </div>
                </div>
              </section>
            </>
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </div>
      <TalkToExpertRegistraionModal
        show={showExpertModal}
        handleClose={handleShowExpertModalClose}
      />
      <Footer />
    </>
  );
}

export default Blog;
