import React, { useState } from "react";
import { Form, Row, Col, Container, Button, Modal } from "react-bootstrap";
import "./RequestCallbackModal.css"; // Import the custom CSS file
import RestClient from "../../services/RestClient";

const RequestCallbackModal = (props) => {
  const [apiMessage, setApiMessage] = useState("");
  const [mandatoryFieldsError, setMandatoryFieldsError] = useState("");
  const [restCallLoading, setRestCallLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    college: "",
    study: "",
    studyOther: "",
    graduationYear: "",
    graduationYearOther: "",
    personalLaptop: "",
    internshipAllowed: "",
    question: "",
    programType: props.programType,
    source: props.source,
  });

  // const mandatoryFields = {
  //   name: "string",
  //   college: "string",
  //   study: "string",
  //   graduationYear: "string",
  //   dedicatedStudyHours: "string",
  //   personalLaptop: "string",
  //   phoneNumber: "string",
  //   email: "string",
  //   internshipAllowed: "string",
  // };

  const mandatoryFields = {"name": "string","college": "string", "study": "string", "graduationYear": "string", "phoneNumber": "string", "email": "string"};

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const constructRequestCallbackPayload = () => {
    const payload = {};
    payload["name"] = formData.name;
    payload["college"] = formData.college;
    payload["study"] = formData.study;
    if (payload["study"] == "other") {
      payload["study"] = formData.studyOther;
    }
    payload["graduationYear"] = formData.graduationYear;
    if (payload["graduationYear"] == "other") {
      payload["graduationYear"] = formData.graduationYearOther;
    }
    // payload["dedicatedStudyHours"] = formData.dedicatedStudyHours;
    // payload["personalLaptop"] = formData.personalLaptop;
    // payload["internshipAllowed"] = formData.internshipAllowed;
    payload["phoneNumber"] = formData.phoneNumber;
    payload["email"] = formData.email;
    // payload["question"] = formData.question;
    payload["source"] = formData.source || "NO_SOURCE";
    payload["programType"] = formData.programType || "NO_PROGRAM_TYPE";

    return payload;
  };

  const validateRequestCallbackPayload = (payload) => {
    const errors = [];
    if (!payload) {
      return errors;
    }
    for (let field of Object.keys(mandatoryFields)) {
      switch (mandatoryFields[field]) {
        case "string":
          if (!payload[field]) {
            errors.push(field);
          }
          break;
        case "array":
          if (!payload[field] || !payload[field].length) {
            errors.push(field);
          }
          break;
        default:
          if (!payload[field]) {
            errors.push(field);
          }
          break;
      }
    }

    return errors;
  };

  const handleSubmit = (event) => {
    console.log(formData);
    const payload = constructRequestCallbackPayload();
    console.log(payload);
    const errors = validateRequestCallbackPayload(payload);
    console.log(errors);
    event.preventDefault();
    if (!!errors && !!errors.length) {
      setMandatoryFieldsError(errors.join(", ") + " are missing");
      return;
    } else {
      setMandatoryFieldsError("");
    }
    // event.target.reset();
    // setApiMessage("Submitting....");
    setRestCallLoading(true);
    RestClient.doPost("programs/callbackRequest", payload)
      .then((response) => {
        if (response.status === 200) {
          console.log(response);
          setFormData({});
          setApiMessage("Great! Our expert will contact you soon");
          event.target.reset();
        }
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
        setApiMessage("Something went wrong. Please try again");
      })
      .finally(() => {
        console.log("hi");
        setRestCallLoading(false);
      });
    setTimeout(() => {
      setApiMessage(null);
    }, 5000);
  };

  const resetState = () => {
    setApiMessage("");
    setMandatoryFieldsError("");
    setRestCallLoading(false);
    setFormData({});
  };

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      className="callback-section"
      dialogClassName="callback-modal"
      tabindex="-1"
    >
      {/* <Modal.Dialog style={{}} className="callback-modal"> */}
        <Modal.Header
          closeButton
          style={{ padding: "10px 30px 0px", paddingBottom: "0px" }}
          onHide={resetState}
        >
          <div>
            <h3>Request Callback</h3>
          </div>
        </Modal.Header>
        <Modal.Body style={{ paddingBottom: "5px" }}>
          <Container fluid>
            <Form onSubmit={handleSubmit}>
              <Row className="form-row">
                <Col lg={6} xs={12}>
                  <Form.Group controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6} xs={12}>
                  <Form.Group controlId="college">
                    <Form.Label>College / University</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your college or university name"
                      name="college"
                      value={formData.college}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="form-row">
                <Col lg={6} xs={12}>
                  <Form.Group className="mb-3" controlId="graduationYear">
                    <Form.Label style={{ fontWeight: "" }}>
                      Graduation Year
                    </Form.Label>
                    <div
                      className="mb-3"
                      style={{ display: "flex", gap: "20px", flexWrap: 'wrap' }}
                    >
                      <div>
                        {" "}
                        <input
                          type="radio"
                          checked={formData.graduationYear == "2024"}
                          name="graduationYear"
                          value="2024"
                          style={{ width: "auto", height: "auto" }}
                          id="2024"
                          onChange={handleChange}
                        />{" "}
                        <label for="2024"> 2024</label>
                      </div>
                      <div>
                        {" "}
                        <input
                          type="radio"
                          checked={formData.graduationYear == "2025"}
                          name="graduationYear"
                          value="2025"
                          style={{ width: "auto", height: "auto" }}
                          onChange={handleChange}
                          id="2025"
                        />{" "}
                        <label for="2025"> 2025</label>
                      </div>
                      <div>
                        {" "}
                        <input
                          type="radio"
                          checked={formData.graduationYear == "other"}
                          name="graduationYear"
                          value="other"
                          style={{ width: "auto", height: "auto" }}
                          id="other_gy"
                          onChange={handleChange}
                        />{" "}
                        <label for="other_gy"> Other</label>
                      </div>
                      {!!formData &&
                        !!formData.graduationYear &&
                        formData.graduationYear == "other" && (
                          <div>
                            <Form.Control
                              name="graduationYearOther"
                              style={{
                                height: "30px",
                                marginTop: "-4px",
                                padding: "0px 10px",
                                outline: "none",
                                boxShadow: "none",
                              }}
                              type="text"
                              placeholder="Eg: 2021"
                              value={formData.graduationYearOther}
                              onChange={handleChange}
                            />
                          </div>
                        )}
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={6} xs={12} style={{flexWrap: 'wrap'}}>
                  <Form.Group className="mb-3" controlId="study">
                    <Form.Label style={{ fontWeight: "" }}>
                      I am pursuing
                    </Form.Label>
                    <div
                      className="mb-3"
                      style={{ display: "flex", gap: "20px", flexWrap: 'wrap' }}
                    >
                      <div>
                        {" "}
                        <input
                          type="radio"
                          checked={formData.study == "CSE"}
                          name="study"
                          value="CSE"
                          style={{ width: "auto", height: "auto" }}
                          id="cse"
                          onChange={handleChange}
                        />{" "}
                        <label for="cse"> CSE</label>
                      </div>
                      <div>
                        {" "}
                        <input
                          type="radio"
                          checked={formData.study == "ECE"}
                          name="study"
                          value="ECE"
                          style={{ width: "auto", height: "auto" }}
                          id="ece"
                          onChange={handleChange}
                        />{" "}
                        <label for="ece"> ECE</label>
                      </div>
                      <div>
                        {" "}
                        <input
                          type="radio"
                          checked={formData.study == "CIVIL"}
                          name="study"
                          value="CIVIL"
                          style={{ width: "auto", height: "auto" }}
                          id="civil"
                          onChange={handleChange}
                        />{" "}
                        <label for="civil"> CIVIL </label>
                      </div>
                      <div>
                        {" "}
                        <input
                          type="radio"
                          checked={formData.study == "MECH"}
                          name="study"
                          value="MECH"
                          style={{ width: "auto", height: "auto" }}
                          id="mech"
                          onChange={handleChange}
                        />{" "}
                        <label for="mech"> MECH </label>
                      </div>
                      <div>
                        {" "}
                        <input
                          type="radio"
                          checked={formData.study == "other"}
                          name="study"
                          value="other"
                          style={{ width: "auto", height: "auto" }}
                          id="other_study"
                          onChange={handleChange}
                        />{" "}
                        <label for="other_study"> Other </label>
                      </div>
                      {!!formData &&
                        !!formData.study &&
                        formData.study == "other" && (
                          <div>
                            <Form.Control
                              style={{
                                height: "30px",
                                marginTop: "-4px",
                                padding: "0px 10px",
                                outline: "none",
                                boxShadow: "none",
                              }}
                              type="text"
                              name="studyOther"
                              placeholder="BSC"
                              value={formData.studyOther}
                              onChange={handleChange}
                            />
                          </div>
                        )}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              {/* <Row className="form-row">
                <Col lg={6} xs={12}>
                  <Form.Group className="mb-3" controlId="dedicatedStudyHours">
                    <Form.Label style={{ fontWeight: "" }}>
                      How many hours/ week you can dedicate for career charge
                      training? (Only if you're selected)
                    </Form.Label>
                    <div
                      className="mb-3"
                      style={{ display: "flex", gap: "20px" }}
                    >
                      <div>
                        {" "}
                        <input
                          type="radio"
                          checked={formData.dedicatedStudyHours == "10-12"}
                          name="dedicatedStudyHours"
                          value="10-12"
                          style={{ width: "auto", height: "auto" }}
                          onChange={handleChange}
                        />{" "}
                        10 - 12{" "}
                      </div>
                      <div>
                        {" "}
                        <input
                          type="radio"
                          checked={formData.dedicatedStudyHours == "12-15"}
                          name="dedicatedStudyHours"
                          value="12-15"
                          style={{ width: "auto", height: "auto" }}
                          onChange={handleChange}
                        />{" "}
                        12 - 15{" "}
                      </div>
                      <div>
                        {" "}
                        <input
                          type="radio"
                          checked={formData.dedicatedStudyHours == "15-20"}
                          name="dedicatedStudyHours"
                          value="15-20"
                          style={{ width: "auto", height: "auto" }}
                          onChange={handleChange}
                        />{" "}
                        15 - 20{" "}
                      </div>
                      <div>
                        {" "}
                        <input
                          type="radio"
                          checked={formData.dedicatedStudyHours == "20+"}
                          name="dedicatedStudyHours"
                          value="20+"
                          style={{ width: "auto", height: "auto" }}
                          onChange={handleChange}
                        />{" "}
                        20+{" "}
                      </div>
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={6} xs={12}>
                  <Form.Group className="mb-3" controlId="personalLaptop">
                    <Form.Label style={{ fontWeight: "" }}>
                      Do you have personal Laptop?
                    </Form.Label>
                    <div
                      className="mb-3"
                      style={{ display: "flex", gap: "20px" }}
                    >
                      <div>
                        {" "}
                        <input
                          type="radio"
                          checked={formData.personalLaptop == "yes"}
                          name="personalLaptop"
                          value={"yes"}
                          style={{ width: "auto", height: "auto" }}
                          onChange={handleChange}
                        />{" "}
                        yes
                      </div>
                      <div>
                        {" "}
                        <input
                          type="radio"
                          checked={formData.personalLaptop == "no"}
                          name="personalLaptop"
                          value={"no"}
                          style={{ width: "auto", height: "auto" }}
                          onChange={handleChange}
                        />{" "}
                        no{" "}
                      </div>
                    </div>
                  </Form.Group>
                </Col>
              </Row> */}

              <Row className="form-row">
                <Col lg={6} xs={12}>
                  <Form.Group controlId="phoneNumber">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="Enter your phone number"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6} xs={12}>
                  <Form.Group controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter your email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* <Row className="form-row">
                <Col>
                  <Form.Group className="mb-3" controlId="internshipAllowed">
                    <Form.Label style={{ fontWeight: "" }}>
                      Are you allowed to do Internship (This question is only
                      applicable for III Year Students)
                    </Form.Label>
                    <div
                      className="mb-3"
                      style={{ display: "flex", gap: "20px" }}
                    >
                      <div>
                        {" "}
                        <input
                          type="radio"
                          checked={formData.internshipAllowed == "YES"}
                          name="internshipAllowed"
                          value="YES"
                          style={{ width: "auto", height: "auto" }}
                          onChange={handleChange}
                        />{" "}
                        Yes{" "}
                      </div>
                      <div>
                        {" "}
                        <input
                          type="radio"
                          checked={formData.internshipAllowed == "NO"}
                          name="internshipAllowed"
                          value="NO"
                          style={{ width: "auto", height: "auto" }}
                          onChange={handleChange}
                        />{" "}
                        No{" "}
                      </div>
                      <div>
                        {" "}
                        <input
                          type="radio"
                          checked={
                            formData.internshipAllowed ==
                            "CHECK_WITH_PLACEMENT_CELL"
                          }
                          name="internshipAllowed"
                          value="CHECK_WITH_PLACEMENT_CELL"
                          style={{ width: "auto", height: "auto" }}
                          onChange={handleChange}
                        />{" "}
                        Need to check with College Placement Cell{" "}
                      </div>
                      <div>
                        {" "}
                        <input
                          type="radio"
                          checked={
                            formData.internshipAllowed ==
                            "NOT_THIRD_YEAR_STUDENT"
                          }
                          name="internshipAllowed"
                          value="NOT_THIRD_YEAR_STUDENT"
                          style={{ width: "auto", height: "auto" }}
                          onChange={handleChange}
                        />{" "}
                        I'm not a III Year Student{" "}
                      </div>
                    </div>
                  </Form.Group>
                </Col>
              </Row> */}
              {/* <Row className="form-row">
                <Col>
                  <Form.Group controlId="question">
                    <Form.Label>I have a question (Ask if any)</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={"1"} // Set the number of rows you want to display
                      cols={1}
                      placeholder="Enter your comments"
                      name="question"
                      value={formData.question}
                      onChange={handleChange}
                      style={{ width: "80%", height: "100px" }}
                    />
                  </Form.Group>
                </Col>
              </Row> */}

              {mandatoryFieldsError && (
                <div>
                  <span style={{ fontSize: "14px", color: "red" }}>
                    *{mandatoryFieldsError}
                  </span>
                </div>
              )}
              {apiMessage && (
                <div style={{ marginLeft: "0px" }}>
                  <span style={{ fontSize: "14px", color: "blue" }}>
                    {apiMessage}
                  </span>
                </div>
              )}

              <Button
                variant="primary"
                type="submit"
                disabled={restCallLoading}
                style={{ marginTop: "10px" }}
              >
                Submit
              </Button>
            </Form>
          </Container>
        </Modal.Body>
      {/* </Modal.Dialog> */}
    </Modal>
  );
};

export default RequestCallbackModal;
