import { useState } from "react";
import TalkToExpertRegistraionModal from "../common/TalkToExpertRegistrationModal";
import cw06 from '../../resources/images/cw-06.svg'
import services02 from "../../resources/images/services-02.svg";
import hft12Svg from "../../resources/images/hft-12.svg";
import services06 from "../../resources/images/services-06.svg";
import hft13Svg from "../../resources/images/hft-13.svg";
import services01 from "../../resources/images/services-01.svg";
import hft7Svg from "../../resources/images/hft-07.svg";



export default function FeaturesNewSection() {

  const [showExpertModal, setShowExpertModal] = useState(false);

  const handleShowExpertModalClose = () => {
    setShowExpertModal(false);
  };

  return (
    <section className="bg-[#2c3e50] py-5">
      <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold tracking-tight text-white sm:text-3xl">
            Your hunt for “best and budget-friendly” learning program ends here
          </h2>
          <p className="mt-4 max-w-2xl text-xl text-gray-200 lg:mx-auto">
            Learn Why Career Charge is the “Right Choice For You”
          </p>
        </div>
        <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          <div className="flex flex-col items-center p-3 rounded-lg shadow-lg bg-white">
          <img src={cw06} alt="Icon" className="h-12 w-12"/>
            <h3 className="mt-3 text-lg font-medium leading-2 text-[#2c3e50] font-extrabold text-gray-900">
              IMP Teaching System
            </h3>
            <p className="mt-2 text-center text-base text-gray-600">
              Instructor (Teach Classes), Mentor (1:1 Guidance & Mock
              Interviews) Problem Solver (Coding Doubt Resolutions)
            </p>
          </div>
          <div className="flex flex-col items-center p-3 rounded-lg shadow-lg bg-white">
          <CodeIcon className="text-[#2c3e50] h-12 w-12" />
            <h3 className="mt-3 text-lg font-medium leading-2 text-[#2c3e50] font-extrabold text-gray-900">
              Mern Stack
            </h3>
            <p className="mt-2 text-center text-base text-gray-600">
              Learn MongoDB, ExpressJS, ReactJS, NodeJS, NextJS and elevate your
              coding journey
            </p>
          </div>
          <div className="flex flex-col items-center p-3 rounded-lg shadow-lg bg-white">
          <img src={services02} alt="Icon" className="h-12 w-12" />
            <h3 className="mt-3 text-lg font-medium leading-6 text-[#2c3e50] font-extrabold text-gray-900">
              Startup Simulation Training
            </h3>
            <p className="mt-2 text-center text-base text-gray-600">
              Work on pilot projects of startups for 2-4 weeks as we create
              startup like scenes and earn a startup ready badge{" "}
            </p>
          </div>
          <div className="flex flex-col items-center p-3 rounded-lg shadow-lg bg-white">
          <img src={hft7Svg} alt="Icon" className="h-12 w-12" />
            <h3 className="mt-3 text-lg font-medium leading-6 text-[#2c3e50] font-extrabold text-gray-900">
              WWH Learning Framework
            </h3>
            <p className="mt-2 text-center text-base text-gray-600">
              Unlock your curiosity in learning any topic by following our WWH
              (What, Why, How) framework.
            </p>
          </div>
          <div className="flex flex-col items-center p-3 rounded-lg shadow-lg bg-white">
          <img src={hft12Svg} alt="Icon" className="h-12 w-12" />
            <h3 className="mt-3 text-lg font-medium leading-6 text-[#2c3e50] font-extrabold text-gray-900">
              Industry Ready Projects
            </h3>
            <p className="mt-2 text-center text-base text-gray-600">
              Boost your skills and build exciting tech products by getting
              hands on with industry ready projects
            </p>
          </div>
          <div className="flex flex-col items-center p-3 rounded-lg shadow-lg bg-white">
          <img src={services01} alt="Icon" className="h-12 w-12" />
            <h3 className="mt-3 text-lg font-medium leading-6 text-[#2c3e50] font-extrabold text-gray-900">
              AI Tools
            </h3>
            <p className="mt-2 text-center text-base text-gray-600">
              Land a job in your own AI ready style by mastering ChatGPT,
              CoPilot, GenAI API Integrations
            </p>
          </div>

          <div className="flex flex-col items-center p-3 rounded-lg shadow-lg bg-white">
          <img src={hft13Svg} alt="Icon" className="h-12 w-15" />
            <h3
              className="mt-3 text-lg font-medium leading-6 text-[#2c3e50] font-extrabold text-gray-900 "
              style={{ fontSize: 17 }}
            >
              Interview Readiness Program
            </h3>
            <p className="mt-2 text-center text-base text-gray-600">
              Your interview readiness training starts from Day 0 with an
              integrated support from our mentors including co-founders.{" "}
            </p>
          </div>

          <div className="flex flex-col items-center p-3 rounded-lg shadow-lg bg-white">
          <img src={services06} alt="Icon" className="h-12 w-12" />
            <h3 className="mt-3 text-lg font-medium leading-6 text-[#2c3e50] font-extrabold text-gray-900">
              No Manipulation
            </h3>
            <p className="mt-2 text-center text-base text-gray-600">
              We Trust You, We Train You, We Place You. Nothing Less, Nothing
              More.
            </p>
          </div>
        </div>
        <div className="text-center mt-10">
          <button onClick={() => setShowExpertModal(true)} className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-primary/90 h-10 px-4 py-2 bg-blue-600 text-white">
            Talk To An Expert Now
          </button>
        </div>
      </div>

      <TalkToExpertRegistraionModal
        show={showExpertModal}
        handleClose={handleShowExpertModalClose}
      />
    </section>
  );
}

function BookIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H20v20H6.5a2.5 2.5 0 0 1 0-5H20" />
    </svg>
  );
}

function BriefcaseIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect width="20" height="14" x="2" y="7" rx="2" ry="2" />
      <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16" />
    </svg>
  );
}

function CodeIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="#0057FC"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polyline points="16 18 22 12 16 6" />
      <polyline points="8 6 2 12 8 18" />
    </svg>
  );
}

function MonitorIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect width="20" height="14" x="2" y="3" rx="2" />
      <line x1="8" x2="16" y1="21" y2="21" />
      <line x1="12" x2="12" y1="17" y2="21" />
    </svg>
  );
}

function NetworkIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect x="16" y="16" width="6" height="6" rx="1" />
      <rect x="2" y="16" width="6" height="6" rx="1" />
      <rect x="9" y="2" width="6" height="6" rx="1" />
      <path d="M5 16v-3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3" />
      <path d="M12 12V8" />
    </svg>
  );
}

function QuoteIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M3 21c3 0 7-1 7-8V5c0-1.25-.756-2.017-2-2H4c-1.25 0-2 .75-2 1.972V11c0 1.25.75 2 2 2 1 0 1 0 1 1v1c0 1-1 2-2 2s-1 .008-1 1.031V20c0 1 0 1 1 1z" />
      <path d="M15 21c3 0 7-1 7-8V5c0-1.25-.757-2.017-2-2h-4c-1.25 0-2 .75-2 1.972V11c0 1.25.75 2 2 2h.75c0 2.25.25 4-2.75 4v3c0 1 0 1 1 1z" />
    </svg>
  );
}

function SquareIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect width="18" height="18" x="3" y="3" rx="2" />
    </svg>
  );
}

function StarIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
    </svg>
  );
}
