import Header from "../../components/header/Header";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { useState } from "react";
import RestClient from "../../services/RestClient";
// import './ContactUs.css';
import Footer from "../../components/footer/Footer";

function HireWithUs() {
  const [hireWithUsRequest, setHireWithUsRequest] = useState({});
  const [apiMessage, setApiMessage] = useState(null);

  const handleHireWithUsFormChange = (event, field) => {
    setHireWithUsRequest({ ...hireWithUsRequest, [field]: event.target.value });
  };

  const handleSubmit = (event) => {
    console.log("handling submit");
    event.preventDefault();
    console.log(hireWithUsRequest);
    if (
      !!hireWithUsRequest.companyLocation &&
      !!hireWithUsRequest.productSegment &&
      !!hireWithUsRequest.websiteUrl &&
      !!hireWithUsRequest.name &&
      !!hireWithUsRequest.email &&
      !!hireWithUsRequest.companyName &&
      !!hireWithUsRequest.phoneNumber &&
      !!hireWithUsRequest.description
    ) {
      setApiMessage("Submitting....");
      RestClient.doPost("hire-with-us", hireWithUsRequest)
        .then((response) => {
          if (response.status === 200) {
            console.log(response);
            event.target.reset();
            setHireWithUsRequest({});
            setApiMessage(
              "Thanks for reaching out to us. We will get back to you soon...",
            );
          }
        })
        .catch((err) => {
          console.log(err);
          setApiMessage("Something went wrong. Please try again");
        });
    } else {
      setApiMessage("Please enter all fields...");
    }
  };

  return (
    <>
      <Header />
      <div className="contactUsBody">
        <div className="contactUsContainer">
          <div className="contactUsInfo">
            <h3>Be a Blackhole in the Space of Tech Talent</h3>
            <h3>And Grab Great Techies @ ZERO COST</h3>
            {/* <p>Cursion about our courses or programs, react out to us on <b>admin@careercharge.in</b></p> */}
          </div>
          <div className="contactFormDiv">
            {/* <center><h4>Hire With Us Form</h4></center><hr /> */}
            <Form
              className="pr-10"
              onSubmit={handleSubmit}
              style={{
                border: "0px solid gray",
                marginTop: "0px",
                padding: "10px",
                borderRadius: "5px",
              }}
              autoComplete="off"
            >
              <Form.Group className="mb-3" controlId="formCompanyName">
                <Form.Label>We're (Company Name)</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ex: Career Charge Private Limited"
                  onChange={(e) => handleHireWithUsFormChange(e, "companyName")}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formCompanyLocation">
                <Form.Label>Located at (Office Location)</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ex: Bangalore"
                  onChange={(e) =>
                    handleHireWithUsFormChange(e, "companyLocation")
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formCompanySegment">
                <Form.Label>Working on (Product Segment)</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ex: EdTech"
                  onChange={(e) =>
                    handleHireWithUsFormChange(e, "productSegment")
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formCompanySegment">
                <Form.Label>Find us (Website URL)</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ex: https://careercharge.in"
                  onChange={(e) => handleHireWithUsFormChange(e, "websiteUrl")}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formUserName">
                <Form.Label>I'm (Your Name)</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ex: Ajay Reddy"
                  onChange={(e) => handleHireWithUsFormChange(e, "name")}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formUserCompanyEmail">
                <Form.Label>Connect with me @ (Email Address)</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Ex: ajay@careercharge.in"
                  onChange={(e) => handleHireWithUsFormChange(e, "email")}
                />
                <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formUserPhone">
                <Form.Label>Call me @ (Mobile Number)</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ex: 7731055489"
                  onChange={(e) => handleHireWithUsFormChange(e, "phoneNumber")}
                />
              </Form.Group>
              {/* <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>How did you hear about us??</Form.Label>
                                <Form.Control type="text" placeholder="Name" onChange={e => handleHireWithUsFormChange(e, "name")}/>
                            </Form.Group> */}
              <Form.Group className="mb-3" controlId="formQuestion">
                <Form.Label>I've a question (Ask us anything)</Form.Label>
                <Form.Control
                  as="textarea"
                  style={{ height: "140px" }}
                  placeholder="Ex: How will you help us source the tech talent?"
                  onChange={(e) => handleHireWithUsFormChange(e, "description")}
                ></Form.Control>
              </Form.Group>
              {apiMessage && (
                <p style={{ paddingBottom: "0px" }}>{apiMessage}</p>
              )}
              <Button variant="primary" size="lg" type="submit">
                Submit
              </Button>
            </Form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default HireWithUs;
