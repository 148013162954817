import { useState } from "react";
import TalkToExpertRegistraionModal from "../common/TalkToExpertRegistrationModal";

export default function ProgramFAQ() {


  const [showExpertModal, setShowExpertModal] = useState(false);

  const handleShowExpertModalClose = () => {
    setShowExpertModal(false);
  };

  return (
    <div className="bg-[#2c3e50] py-12">
      <div className="max-w-6xl mx-auto px-2">
        <h2 className="text-white text-3xl font-semibold mb-10 text-center">
        Your Doubts, Our Answers
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <details className="mb-4 bg-white rounded-lg">
              <summary className="flex justify-between items-center p-3 cursor-pointer">
               I’m from a Mechanical/ Civil Engineering (Non-IT) background. Shall I get a job in the IT industry?
                <PlusIcon className="plusIcon text-black" />
                <MinusIcon className="minusIcon text-black" />
              </summary>
              <div className="px-3 pt-2 pb-2 font-bold">
                <p>
                  Yes
                </p>
              </div>
            </details>
            <details className="mb-4 bg-white rounded-lg">
              <summary className="flex justify-between items-center p-3 cursor-pointer">
              I’m from CSE/ ECE. I’ve already learnt skills and will easily get an IT Job. How does Career Charge help me?
                <PlusIcon className="plusIcon text-black" />
                <MinusIcon className="minusIcon text-black" />
              </summary>
              <div className="px-3 pt-2 pb-2 font-bold">
                <p>
                We’re glad that you’re from a CS background and you’ve already climbed from zero to one. It's time to go from 1-10. Now, you know the basic knowledge but getting a high paid job in a product based company and the <b>step-by-step process</b> is what you lack or thousands of skilled engineers like you lack.
                </p>
              </div>
            </details>
            <details className="mb-4 bg-white rounded-lg">
              <summary className="flex justify-between items-center p-3 cursor-pointer">
              I’m studying now and unable to pay the fee. What should I do now?

                <PlusIcon className="plusIcon text-black" />
                <MinusIcon className="minusIcon text-black" />
              </summary>
              <div className="px-3 pt-2 pb-2 font-bold">
                <p>
                We have 1 (Downpayment)+ 5 (EMI’s) months to pay the fee. It helps bring authenticity and responsibility/ seriousness while learning. Free is always taken for granted but we make it affordable by seeing your enthusiasm for coding
                </p>
              </div>
            </details>
            <details className="mb-4 bg-white rounded-lg">
              <summary className="flex justify-between items-center p-3 cursor-pointer">
              What’s so special about career charge? What are you doing differently than tons of other coaching institutes?

                <PlusIcon className="plusIcon text-black" />
                <MinusIcon className="minusIcon text-black" />
              </summary>
              <div className="px-3 pt-2 pb-2 font-bold">
                <p>
                We don’t see ours as a coaching institute. It’s a tech company helping techies/ aspiring techies to elevate their LSG (Learning, Salary, Growth) potential. Startup Simulation Training to make you startup-ready is a game changer that attracted dozens of startups in the waiting list to join us as hiring partners. We have an AI first approach in learning and train you with AI tools to be future ready (AI won’t replace you. But the people who can use AI will). WWH (What, Why, How) Teaching Framework will make your learning a cakewalk and is our patent. Instructors and advisors are working across Singapore, US etc which exposes you to the abroad opportunities too.                </p>
              </div>
            </details>
            {/* <details className="mb-4 bg-white rounded-lg">
              <summary className="flex justify-between items-center p-3 cursor-pointer">
              I’m studying now and unable to pay the fee. What should I do now?

                <PlusIcon className="plusIcon text-black" />
                <MinusIcon className="minusIcon text-black" />
              </summary>
              <div className="px-3 pt-2 pb-2 font-bold">
                <p>
                We have 1 (Downpayment)+ 5 (EMI’s) months to pay the fee. It helps bring authenticity and responsibility/ seriousness while learning. Free is always taken for granted but we make it affordable by seeing your enthusiasm for coding
                </p>
              </div>
            </details>
            <details className="mb-4 bg-white rounded-lg">
              <summary className="flex justify-between items-center p-3 cursor-pointer">
              Career Charge doesn’t even have a track record of placements. How can I trust you?

                <PlusIcon className="plusIcon text-black" />
                <MinusIcon className="minusIcon text-black" />
              </summary>
              <div className="px-3 pt-2 pb-2 font-bold">
                <p>
                Our team collectively has a track record of helping 100+ students get into high-paying tech jobs. We’re planning to implement the same strategies and learning frameworks to help you get into tech jobs as developers. We Trust You, We Train You and We place you. As we’re from IIIT, our strong alumni network is working in 100+ tech companies across India, US, Canada, Singapore, Germany, Australia etc. We had a word with them and agreed to refer our learners to their companies in the coming months. Our success is also dependant on your success as we’re starting out and as you’re a part of our first batch, the potential to get hired is very very high as the CCBP/ UpGrad etc have thousands of concurrent learners waiting to be placed and lacked personal focus in which we have laser focus to make it a grand success with 100% placement. We have confidence and so do you! If so, join us and take charge of your career.                </p>
              </div>
            </details> */}
          </div>
          <div>
            <details className="mb-4 bg-white rounded-lg">
              <summary className="flex justify-between items-center p-3 cursor-pointer">
              Is there any job guarantee? If not, why should I take your program/ course?
                <PlusIcon className="plusIcon text-black" />
                <MinusIcon className="minusIcon text-black" />
              </summary>
              <div className="px-3 pt-2 pb-2 font-bold">
                <p>
                100% Placement Support is given till their placement.
                </p>
              </div>
            </details>
            <details className="mb-4 bg-white rounded-lg">
              <summary className="flex justify-between items-center p-3 cursor-pointer">
              Why Career Charge is best for my upskilling?
                <PlusIcon className="plusIcon text-black" />
                <MinusIcon className="minusIcon text-black" />
              </summary>
              <div className="px-3 pt-2 pb-2 font-bold">
                <p>
                Your entry into the tech industry is not limited to WITCH (Wipro, Infosys, TCS, Cognizant, HCL) companies and doesn’t end with MAANG (Meta, Apple, Amazon, Netflix, Google). You shouldn’t end up in SBC’s (Service Based Companies) with very slow career growth, meager 5% increments YoY and 50% time wasted on bench. We wanted to open the doors into the real tech industry i.e, product based companies where your salary is at-least 2-3X the base salary of SBC’s, 5X exposure to the early stage building of a company and 10X learnings. We’re focussing on creating a tech powerhouse for startups (10-50 employees). We want to be the FedEx of tech-talent supply for startups. 
                </p>
              </div>
            </details>
            <details className="mb-4 bg-white rounded-lg">
              <summary className="flex justify-between items-center p-3 cursor-pointer">
              How many hours/ week are required to become a very good developer?
                <PlusIcon className="plusIcon text-black" />
                <MinusIcon className="minusIcon text-black" />
              </summary>
              <div className="px-3 pt-2 pb-2 font-bold">
                <p>
                We require 18 hours/ week minimum and a maximum of 25 hours/ week
                </p>
              </div>
            </details>
            <details className="mb-4 bg-white rounded-lg">
              <summary className="flex justify-between items-center p-3 cursor-pointer">
              What to do if I get a doubt while solving a coding problem/ assignment?
                <PlusIcon className="plusIcon text-black" />
                <MinusIcon className="minusIcon text-black" />
              </summary>
              <div className="px-3 pt-2 pb-2 font-bold">
                <p>
                Don’t worry! We got your back! We have Teaching Assistants to help you in solving a problem on the same day. All you need to do is just raise a request on our website and TA’s will get back to you over a video call.
                </p>
              </div>
            </details>
            {/* <details className="mb-4 bg-white rounded-lg">
              <summary className="flex justify-between items-center p-3 cursor-pointer">
              Why is Career Charge focusing only on startups?
                <PlusIcon className="plusIcon text-black" />
                <MinusIcon className="minusIcon text-black" />
              </summary>
              <div className="px-3 pt-2 pb-2 font-bold">
                <p>
                India has the 3rd largest startup ecosystem in the world. Startups in the country have been able to create an estimated 40,000 new jobs over the year. There are 2-3 tech startups born every day and with an YoY growth of 15%. We have 72,993 tech startups in India as of June, 2022.                </p>
              </div>
            </details>
            <details className="mb-4 bg-white rounded-lg">
              <summary className="flex justify-between items-center p-3 cursor-pointer">
              What are the career options after completing the program?

                <PlusIcon className="plusIcon text-black" />
                <MinusIcon className="minusIcon text-black" />
              </summary>
              <div className="px-3 pt-2 pb-2 font-bold">
                <p>
                  Full Stack Developer, Front-End Developer, Back-End Developer, React Developer, Node.JS Developer, Database Engineer
                </p>
              </div>
            </details> */}
          </div>
        </div>
      </div>
      <div className="text-center mt-6">
          <button onClick={() => setShowExpertModal(true)} className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-blue-700 h-10 px-4 py-2 bg-blue-600 text-white">
           Have More Doubts - Talk to our Expert
          </button>
      </div>
      <TalkToExpertRegistraionModal
        show={showExpertModal}
        handleClose={handleShowExpertModalClose}
      />
    </div>
    
  );
}

function MinusIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="15%"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M5 12h14" />
    </svg>
  );
}

function PlusIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="15%"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M5 12h14" />
      <path d="M12 5v14" />
    </svg>
  );
}
