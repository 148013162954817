import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import mainLogo from "../../resources/images/career-charge-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faLinkedin,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import SDEFullStackProgram from "../../components/programs/SDEFullStackProgram";
import FeaturesSection from "../../components/programs/FeaturesSections";
import ProgramHeroSection from "../../components/programs/ProgramHeroSection";
import ProgramProjects from "../../components/programs/ProgramProjects";
import CourseCurriculum from "../../components/programs/CourseCurriculum";
import ProgramFAQ from "../../components/programs/ProgramFAQ";
import Instructors from "../../components/programs/Instructors";
import Testimonials from "../../components/programs/Testimonials";
import HiringPartners from "../../components/programs/HiringPartners";
import DownloadCurriculum from "../../components/programs/DownloadCurriculum";
import FeaturesNewSection from "../../components/programs/FeaturesNewSection";
import Advisors from "../../components/programs/advisors";

function SDEFullStackProgramView() {
  return (
    <>
      <Header />
      <ProgramHeroSection source="PROGRAM_PAGE" programType="FULL_STACK_PAID_COURSE_MAR_2024"/>
      <FeaturesNewSection />
      <ProgramProjects />
      <CourseCurriculum />
      <DownloadCurriculum />
      <Instructors />
      <Advisors />
      <Testimonials />
      <HiringPartners />
      <ProgramFAQ />
      <Footer />
    </>
  );
}

export default SDEFullStackProgramView;
