import React from 'react';

const AboutUs = () => {
  return (
    <div className="bg-gray-100 py-10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-center text-3xl leading-9 font-extrabold text-gray-900">
          About Us
        </h2>
        <div className="mt-10">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <div className="rounded-lg border text-card-foreground bg-white shadow overflow-hidden sm:rounded-lg">
              <div className="flex flex-col p-6">
                <h3 className="text-2xl font-semibold whitespace-nowrap leading-none tracking-tight mb-2">What we do?</h3>
                <p className="mt-3 text-sm text-gray-800">
                  We believe in our visionary statement “skills can take you to the places where mass-hiring can’t”. We open your doors into the tech world as a software developer via 6-month power charged training program. Career Charge is your access key to 10,000+ tech startups hunting for a 100% charged techie, exactly like you.
                </p>
              </div>
            </div>

            <div className="rounded-lg border text-card-foreground bg-white shadow overflow-hidden sm:rounded-lg">
              <div className="flex flex-col p-6">
                <h3 className="text-2xl font-semibold whitespace-nowrap leading-none tracking-tight mb-2">Why we do?</h3>
                <p className="mt-3 text-sm text-gray-800">
                  The career for a Tier- 3 & 4 college student is not limited to WITCH (Wipro, Infosys, TCS, Cognizant, HCL) and doesn't end with MAANG (Meta, Apple, Amazon, Netflix, Google) companies. We change how you look at your LSG (Learning, Salary, Growth) potential by paving a golden path for a dream career start.
                </p>
              </div>
            </div>

            <div className="rounded-lg border text-card-foreground bg-white shadow overflow-hidden sm:rounded-lg">
              <div className="flex flex-col p-6">
                <h3 className="text-2xl font-semibold whitespace-nowrap leading-none tracking-tight mb-2">Who we are?</h3>
                <p className="mt-3 text-sm text-gray-800">
                  We are career chargers. We have a deep connection to the rural India, our academic roots emerged from IIIT and contribution as SDE's (Software Development Engineer) in tech startups. We're crazy about tech and its future. We're excited about building our company by building your careers. Its time to take charge of your career.
                </p>
              </div>
            </div>

            <div className="rounded-lg border text-card-foreground bg-white shadow overflow-hidden sm:rounded-lg col-span-1 md:col-span-2 lg:col-span-3">
              <div className="flex flex-col p-6">
                <h3 className="text-2xl font-semibold whitespace-nowrap leading-none tracking-tight mb-2">How we do?</h3>
                <p className="mt-3 text-sm text-gray-800">
                  We Trust you, We Train You, We Place You. We select aspirational students pursuing III and IV year engineering. We take them on a 45D Free Training Program (100% live classes online) and watch closely. If our works and your actions matched, we open your doors to the 6-months power charged training program.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
