import { useState } from "react";
import homePageLogo from "../../resources/images/hr-01.png";
import TalkToExpertRegistraionModal from "../common/TalkToExpertRegistrationModal";
// import homePageLogo from '../../resources/images/homepage.jpeg';
import CodingImg from "../../resources/images/home-coding.jpg";
import landingBannerImage from "../../resources/images/banner_landing_image.png";
import CodingImgTransparant from "../../resources/images/coding-img-transparent.png";
import AbroadJobsWebinarImage from "../../resources/images/abroad_jobs_webinar.png";
import MasterClassRegistrationModal from "../common/MasterClassRegistrationModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle } from "@fortawesome/fontawesome-free-solid";
import ProgramCard from "../programs/ProgramCard";
import upskillingVector from "../../resources/images/upskilling_vector.avif";
import { HomeBannerPrograms } from "../../constants/ProgramConstants";
import "./HomeBannerNew.css";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import newBannerImage from "../../resources/images/cc_banner_image.png";
import newBannerImageTransparent from "../../resources/images/cc_banner_image_transparent.png";

const HomeBannerNew = () => {
  const [showExpertModal, setShowExpertModal] = useState(false);
  const [
    showMasterClassRegistrationModal,
    setShowMasterClassRegistrationModal,
  ] = useState(false);
  const handleShowExpertModalClose = () => {
    setShowExpertModal(false);
  };
  const handleShowMasterClassRegistrationModalClose = () => {
    setShowMasterClassRegistrationModal(false);
  };

  const handleMasterClassRegistrationClick = () => {
    // setShowMasterClassRegistrationModal(true);  // COMMENT: dec 8, commented attaching below form
    // window.open("https://events.teams.microsoft.com/event/19f0209c-7f45-44aa-b190-bca9789c641a@6b354172-36d8-4ee2-bb02-888d39ad9e09"); // FULLSTACK_WEBINAR registration, commented on: dec 12
    window.open(
      "https://events.teams.microsoft.com/event/45a4c5b8-85c4-43cb-9d90-e9fde8977314@6b354172-36d8-4ee2-bb02-888d39ad9e09",
    ); // ABROAD_JOBS_WEBINAR registration
  };

  return (
    <>
      {/* <section className="section background-grey opt10 spdb overflow-hidden" style={{paddingTop: "0px"}}>
            <div className="container" style={{marginTop: "10px", marginBottom: '120px'}}>
                <div className="row flex-align-cx home-banner-section" style={{position: 'relative', zIndex: 100}}>
                    <div className="col-lg-4" style={{marginTop: "100px"}}>
                        <div className="heading mb32">
                            <h2 className="heading-title size-xxl" style={{fontFamily: "sans-serif", fontWeight: 500}}>Take Charge of Your <span>Career</span></h2>
                            <br></br>
                            <div className="heading-desc" style={{fontSize: '20px'}}> Skills can take you to places where mass hiring can't. There are 25,000+ tech startups in India hunting for a charged techie, exactly like YOU</div>
                        </div>
                        <div className="button-wrap popup-wrapper" style={{marginTop: '48px'}} >
                            <div style={{textDecoration: "none", cursor: 'pointer', width: '100%', justifyContent: 'center'}} className="button fullfield-grey xs-mb10" title="Get Started" onClick={() => setShowExpertModal(true)}>Talk to Expert</div>
                        </div>
                        
                    </div>
                    <div className='col-lg-1'>

                    </div>
                    <div className="col-lg-7" style={{marginTop: "100px", padding: 0}}>
                            <div className='program-cards-section'>
                                {
                                    HomeBannerPrograms.map(program => {
                                        return <ProgramCard programDetails={program}/>
                                    })
                                }
                            </div>
                    </div>
                </div>
            </div>
            <TalkToExpertRegistraionModal show={showExpertModal} handleClose={handleShowExpertModalClose}/>
            <MasterClassRegistrationModal masterClassType='CHAT_GPT_MASTER_CLASS' show={showMasterClassRegistrationModal} handleClose={handleShowMasterClassRegistrationModalClose}/>
        </section> */}
      <section
        className="section background-grey opt10 spdb overflow-hidden home-banner-section"
        style={{ paddingTop: "0px" }}
      >
        <div className="home-banner-div">
          <div className="home-banner-left">
            <div className="heading mb32">
              <h2
                className="heading-title size-xxl"
                style={{ fontFamily: "sans-serif", fontWeight: 500 }}
              >
                Take Charge of Your <span>Career</span>
              </h2>
              <br></br>
              <div className="heading-desc" style={{ fontSize: "20px" }}>
                Skills can take you to places where mass hiring can't. There are
                25,000+ tech startups in India hunting for a charged techie,
                exactly like YOU
              </div>
              <div
                className="button-wrap popup-wrapper expert-button-wrap"
                style={{ marginTop: "48px" }}
              >
                <div
                  style={{
                    textDecoration: "none",
                    cursor: "pointer",
                    width: "100%",
                    justifyContent: "center",
                  }}
                  className="button fullfield-grey xs-mb10 expert-button"
                  title="Get Started"
                  onClick={() => setShowExpertModal(true)}
                >
                  Talk to Expert
                </div>
              </div>
            </div>
          </div>
          <div className="home-banner-right">
            <div className="program-cards-section">
              {HomeBannerPrograms.map((program) => {
                return (
                  <ProgramCard
                    programDetails={program}
                    source="HOME_PAGE_BANNER_SECTION"
                  />
                );
              })}
            </div>
          </div>
        </div>
        {/* <div className="container feature-strip">
          <div>
            {" "}
            <FontAwesomeIcon icon={faStar} /> Live Classes
          </div>
          <div>
            {" "}
            <FontAwesomeIcon icon={faStar} /> Industry Professionals
          </div>
          <div>
            {" "}
            <FontAwesomeIcon icon={faStar} /> Hight Quality for Affordable price
          </div>
          <div>
            {" "}
            <FontAwesomeIcon icon={faStar} /> Quick Doubt Solving
          </div>
        </div> */}
        <TalkToExpertRegistraionModal
          show={showExpertModal}
          handleClose={handleShowExpertModalClose}
        />
        <MasterClassRegistrationModal
          masterClassType="CHAT_GPT_MASTER_CLASS"
          show={showMasterClassRegistrationModal}
          handleClose={handleShowMasterClassRegistrationModalClose}
        />
      </section>
    </>
  );
};

export default HomeBannerNew;
