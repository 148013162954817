import reactIcon from '../resources/icons/icons8-react.png';
import nodeIcon from '../resources/icons/icons8-nodejs.svg';
import mongoIcon from '../resources/icons/mongo_db.png';
import Badge from 'react-bootstrap/Badge';
import chatGptIcon from '../resources/icons/icons8-chatgpt.svg';
import copilotIcon from '../resources/icons/icons8-windows-copilot.svg';
import awsIcon from '../resources/icons/icons8-amazon-web-services.svg';
import hourClassIcon from '../resources/icons/icons8-hourglass.gif';
import hourClassIconColor from '../resources/icons/icons8-hourglass_color.gif';

import html5Icon from '../resources/icons/icons8-html.svg';
import css3Icon from '../resources/icons/icons8-css.svg';
import jsGif from '../resources/icons/icons8-js.gif';
import githubIcon from '../resources/icons/icons8-github-48.svg';
import linkedinIcon from '../resources/icons/icons8-linkedin.svg';
import rupeeIcon from '../resources/icons/icons8-rupee-48.png';

export const HomeBannerPrograms = [
    {
        'badgeText': 'Live Classes',
        'programTitle': 'AI Ready Full Stack Developer Program',
        'eligibleInfo': '3rd year, 4th year, passed out',
        'description': 'Join our 6 months training program & land a high paying job at "Tech Startups"',
        'subjectIcons': [
            {
                'icon': reactIcon,
                'width': '40',
                'height': '40',
                'alt': 'react'
            },
            {
                'icon': nodeIcon,
                'width': '48',
                'height': '48',
                'alt': 'node'
            },
            {
                'icon': mongoIcon,
                'width': '40',
                'height': '36',
                'alt': 'mongo'
            },
            {
                'icon': awsIcon,
                'width': '48',
                'height': '40',
                'alt': 'aws'
            },
            {
                'icon': chatGptIcon,
                'width': '48',
                'height': '40',
                'alt': 'chatGPT'
            }
        ],
        'startDate': '13th June 2024',
        'bottomInfo': {
            'icon': {
                'icon': hourClassIconColor,
                'width': '36px',
                'height': '36px'
            },
            'description': 'Truly Limited Seats\&nbsp;Available'
        },
        'detailsUrl': '/program/software-development-full-stack-program',
        'requestCallbackId': 'FULL_STACK_PAID_COURSE_MAR_2024'
    },
    {
        'badgeText': 'Live Classes',
        'programTitle': 'Full Stack Developer "Readiness Program"',
        'eligibleInfo': 'Any Degree',
        'description': 'Join our "30 Days Readiness Program" and build a roadmap to learn full stack',
        'subjectIcons': [
            {
                'icon': html5Icon,
                'width': '48',
                'height': '48',
                'alt': 'react'
            },
            {
                'icon': css3Icon,
                'width': '48',
                'height': '48',
                'alt': 'node'
            },
            {
                'icon': jsGif,
                'width': '48',
                'height': '48',
                'alt': 'mongo'
            },
            {
                'icon': githubIcon,
                'width': '48',
                'height': '48',
                'alt': 'aws'
            },
            {
                'icon': linkedinIcon,
                'width': '48',
                'height': '48',
                'alt': 'chatGPT'
            }
        ],
        'startDate': '18th April 2024',
        'bottomInfo': {
            'icon': {
                'icon': rupeeIcon,
                'width': '36px',
                'height': '36px'
            },
            'description': '<span style="font-size: 22px;font-weight:600">499&nbsp;/-&nbsp;Only</span>'
        },
        'applyNowFormLink': 'https://docs.google.com/forms/d/e/1FAIpQLSeeD9IaDwDcqlLMC16j8R_SfnfoDYbyUfeZXNnVBY3G_5UFnw/viewform',
        // 'requestCallbackId': 'WEB_DEVELOPER_READINESS_FREE_COURSE_APR_2024',
        'buyNowLink': 'https://learn.careercharge.in/courses/65f3e13716ea1f039ec6cb69'
    }
]