import React, { useState } from "react";
import placeholderImage from "../../resources/images/placeholder.svg";
import TalkToExpertRegistraionModal from "../common/TalkToExpertRegistrationModal";
import ShopKart from "../../resources/images/shopkart.webp";
import ShopKart1 from "../../resources/images/shopkart1.webp";
import eventbuzz from "../../resources/images/eventbuzz.webp";
import shortenIt from "../../resources/images/shortenit.webp";



const ProgramProjects = () => {

  const [showExpertModal, setShowExpertModal] = useState(false);

  const handleShowExpertModalClose = () => {
    setShowExpertModal(false);
  };

  return (
    <div className="bg-[#2c3e50] text-white">
      <div className="bg-[#2c3e50] max-w-6xl mx-auto px-4 py-12 text-center">
        <h2 className="text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
        Beyond the Code: Explore Our Projects
        </h2>
        <p className="text-center text-white mb-12">
        Our roots at IIIT believed in the power of “Learning By Doing”. At Career Charge, we integrate this philosophy with our very own “WWH” (What, Why, How) learning framework. Get ready to level up your skills as you go on a journey to build projects like a seasoned 2-year experienced full stack developer!

        </p>
        <div className="flex justify-between items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="text-gray-400 cursor-pointer"
          ></svg>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white shadow-lg rounded-lg p-3">
              <img
                src={ShopKart1}
                alt="Project Image"
                className="mb-4"
                width="100%"
                height="150"
                style={{ aspectRatio: "250 / 150", objectFit: "fill" }}
              />
              <h3 className="font-semibold text-gray-800 text-lg mb-2">
              ShopKart: A Full Stack E-Commerce App
              </h3>
              <p className="text-gray-600 mb-4">
                Build a fully functional e-commerce app like Amazon, Flipkart, or Myntra.
              </p>
              <div className="flex flex-wrap gap-2">
                <div className="inline-flex items-center rounded-full whitespace-nowrap border px-2.5 py-0.5 w-fit text-xs font-semibold text-gray-800 transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent  text-primary-foreground hover:bg-primary/80">
                  React
                </div>
                <div className="inline-flex items-center rounded-full whitespace-nowrap border px-2.5 py-0.5 w-fit text-xs font-semibold text-gray-800 transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent  text-primary-foreground hover:bg-primary/80">
                  Node.js
                </div>
                <div className="inline-flex items-center rounded-full whitespace-nowrap border px-2.5 py-0.5 w-fit text-xs font-semibold text-gray-800 transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent  text-primary-foreground hover:bg-primary/80">
                  MongoDB
                </div>
                <div className="inline-flex items-center rounded-full whitespace-nowrap border px-2.5 py-0.5 w-fit text-xs font-semibold text-gray-800 transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-primary-foreground hover:bg-primary/80">
                  JavaScript
                </div>
              </div>
            </div>
            <div className="bg-white shadow-lg rounded-lg p-3">
              <img
                src={eventbuzz}
                alt="Project Image"
                className="mb-4"
                width="100%"
                height="150"
                style={{ aspectRatio: "250 / 150", objectFit: "cover" }}
              />
              <h3 className="font-semibold text-gray-800 text-lg mb-2">
                EventBuzz: A Full Stack Event Booking Experience.
              </h3>
              <p className="text-gray-600 mb-4">
                Build a fully functional event booking app like BookMyShow or EventBrite.
              </p>
              <div className="flex flex-wrap gap-2">
              <div className="inline-flex items-center rounded-full whitespace-nowrap border px-2.5 py-0.5 w-fit text-xs font-semibold text-gray-800 transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent  text-primary-foreground hover:bg-primary/80">
                  React
                </div>
                <div className="inline-flex items-center rounded-full whitespace-nowrap border px-2.5 py-0.5 w-fit text-xs font-semibold text-gray-800 transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent  text-primary-foreground hover:bg-primary/80">
                  Node.js
                </div>
                <div className="inline-flex items-center rounded-full whitespace-nowrap border px-2.5 py-0.5 w-fit text-xs font-semibold text-gray-800 transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent  text-primary-foreground hover:bg-primary/80">
                  MongoDB
                </div>
                <div className="inline-flex items-center rounded-full whitespace-nowrap border px-2.5 py-0.5 w-fit text-xs font-semibold text-gray-800 transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-primary-foreground hover:bg-primary/80">
                  JavaScript
                </div>
              </div>
            </div>
            <div className="bg-white shadow-lg rounded-lg p-3">
              <img
                src={shortenIt}
                alt="Project Image"
                className="mb-4"
                width="100%"
                height="150"
                style={{ aspectRatio: "250 / 150", objectFit: "cover" }}
              />
              <h3 className="font-semibold text-gray-800 text-lg mb-2">
                ShortenIt: A Full Stack URL Shortening Service.
              </h3>
              <p className="text-gray-600 mb-4">
                Build a fully functional highly scalable URL shortening service like Bitly or TinyURL.
              </p>
              <div className="flex flex-wrap gap-2">
              <div className="inline-flex items-center rounded-full whitespace-nowrap border px-2.5 py-0.5 w-fit text-xs font-semibold text-gray-800 transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent  text-primary-foreground hover:bg-primary/80">
                  React
                </div>
                <div className="inline-flex items-center rounded-full whitespace-nowrap border px-2.5 py-0.5 w-fit text-xs font-semibold text-gray-800 transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent  text-primary-foreground hover:bg-primary/80">
                  Node.js
                </div>
                <div className="inline-flex items-center rounded-full whitespace-nowrap border px-2.5 py-0.5 w-fit text-xs font-semibold text-gray-800 transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent  text-primary-foreground hover:bg-primary/80">
                  MongoDB
                </div>
                <div className="inline-flex items-center rounded-full whitespace-nowrap border px-2.5 py-0.5 w-fit text-xs font-semibold text-gray-800 transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-primary-foreground hover:bg-primary/80">
                  JavaScript
                </div>

              </div>
            </div>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="text-gray-400 cursor-pointer"
          ></svg>
        </div>
        <div className="text-center mt-10">
          <button onClick={() => setShowExpertModal(true)} className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-primary/90 h-10 px-4 py-2 bg-blue-600 text-white">
            Talk To An Expert Now
          </button>
        </div>
      </div>

      <TalkToExpertRegistraionModal
        show={showExpertModal}
        handleClose={handleShowExpertModalClose}
      />
    </div>
  );
};

export default ProgramProjects;
