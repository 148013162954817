import hft7Svg from "../../resources/images/hft-07.svg";
import hft8Svg from "../../resources/images/hft-08.svg";
import hft9Svg from "../../resources/images/hft-09.svg";
import hft10Svg from "../../resources/images/hft-10.svg";
import hft11Svg from "../../resources/images/hft-11.svg";
import hft12Svg from "../../resources/images/hft-12.svg";
import hft13Svg from "../../resources/images/hft-13.svg";
import hft14Svg from "../../resources/images/hft-14.svg";
import cw03 from "../../resources/images/cw-03.svg";
import cw06 from "../../resources/images/cw-06.svg";
import services01 from "../../resources/images/services-01.svg";
import services02 from "../../resources/images/services-02.svg";
import services06 from "../../resources/images/services-06.svg";

const Features = () => {
  return (
    <section className="section spdtb">
      <div className="container">
        <div className="heading align-center">
          <div className="heading-sub color-navy">WHY we are THE best</div>
          <h2 className="heading-title size-l">
            Learn why Career Charge is the right choice for you
          </h2>
        </div>
        <div className="block-icon-box layout-02">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="icon-box align-center p30">
                <div className="inner">
                  <div className="icon flex justify-center">
                    <img src={hft7Svg} alt="Icon" />
                  </div>
                  <div className="content">
                    <h3 className="title">100% Live Classes</h3>
                    <div className="desc">The classes are "live"ly, 100%</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="icon-box align-center p30">
                <div className="inner">
                  <div className="icon flex justify-center">
                    <img src={services02} alt="Icon" style={{ width: "23%" }} />{" "}
                  </div>
                  <div className="content">
                    <h3 className="title">Startup Simulation</h3>
                    <div className="desc">
                      We create startup like scenes to experience how they work
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="icon-box align-center p30">
                <div className="inner">
                  <div className="icon flex justify-center">
                    <img src={cw06} alt="Icon" />
                  </div>
                  <div className="content">
                    <h3 className="title">
                      1:1 Mentorship by Industry experts
                    </h3>
                    <div className="desc">
                      By the startup guys, For the startup guys
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="icon-box align-center p30">
                <div className="inner">
                  <div className="icon flex justify-center">
                    <img src={cw03} alt="Icon" />
                  </div>
                  <div className="content">
                    <h3 className="title">Quick Doubt Resolution </h3>
                    <div className="desc">
                      All your doubts will be crystal clear in 24 hours
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="icon-box align-center p30">
                <div className="inner">
                  <div className="icon flex justify-center">
                    <img src={services01} alt="Icon" style={{ width: "23%" }} />
                  </div>
                  <div className="content">
                    <h3 className="title">Hack - O - The - Whack</h3>
                    <div className="desc">
                      We challenge you consistently to be your best version
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="icon-box align-center p30">
                <div className="inner">
                  <div className="icon flex justify-center">
                    <img src={hft12Svg} alt="Icon" />
                  </div>
                  <div className="content">
                    <h3 className="title">Real Time Live Projects</h3>
                    <div className="desc">
                      You will be working on Real-Time Live Projects!!
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="icon-box align-center p30">
                <div className="inner">
                  <div className="icon flex justify-center">
                    <img src={hft13Svg} alt="Icon" />
                  </div>
                  <div className="content">
                    <h3 className="title">Mock Interviews + Resume </h3>
                    <div className="desc">
                      You'll be "Ready to Face" any interview
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="icon-box align-center p30">
                <div className="inner">
                  <div className="icon flex justify-center">
                    <img src={services06} alt="Icon" style={{ width: "23%" }} />
                  </div>
                  <div className="content">
                    <h3 className="title">No Manipulations</h3>
                    <div className="desc">
                      We Trust You, We Train You, We Place You. Nothing less,
                      Nothing more !{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
