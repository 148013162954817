export const monthName = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                 "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const getDateFromTimeMillis = (timeMillis)=> {
    let date = new Date(timeMillis);
    return `${date.getDate()} ${monthName[date.getMonth()]}, ${date.getFullYear()}`
}

export const getISTTimeFormatFromUTCTimeString = (utcTimeString) => {
    const utcDate = new Date(utcTimeString);
    const istDate = new Date(utcDate.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }));
    
    // Get the year, month, day, hours, and minutes in IST time
    const year = istDate.getFullYear();
    const month = istDate.getMonth() + 1; // Add 1 because getMonth() returns zero-based month (0 for January)
    const day = istDate.getDate();
    const hours = istDate.getHours();
    const minutes = istDate.getMinutes();
    
    // Format the date-time value as required (with zero padding if needed)
    const formattedDateTime = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}T${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;

    return formattedDateTime
}